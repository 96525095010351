.MJcontainer {
  padding-top: 110px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f9fa;
}

.whoMJ {
  margin: 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c6dfeb;
  width: 80%;
  min-height: 500px;
  border-radius: 15px;
}

.gifImg {
  width: 500px;
  height: 500px;
}

.MrJOffice {
  width: 339px;
}

.comingsoon {
  color: var(--Medium-grey, rgba(222, 222, 222, 0.8));
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.comingsoon2 {
  color: var(--Grey, #9e9e9e);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.linkTextAccess {
  color: rgba(10, 71, 119, 1);
  font-size: 21px;
  font-weight: 700;
  line-height: 29.6px;
}

.text2Q1 {
  padding-top: 30px;
  color: var(--White, #fff);
  padding-bottom: 26px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.1px;
}

.MrJInfo {
  min-height: 638px;
  padding: 100px 20px 40px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/mrJournaler/backgroundMj.svg");
  background-repeat: no-repeat;
  background-size: cover;

  .MJInfoInner {
    display: flex;
    justify-content: space-between;
    /* align-items : center; */
    flex-direction: column;
    gap: 17px;
    text-align: center;
    padding: 0px;
  }

  .MJTitleDis {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    color: var(--Black, #202020);
  }

  .MJInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 28px;
  }

  .MJInfoLeft {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 702px;
    min-height: 148px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #202020;
    text-align: left;
  }

  .MJInfoRight {}

  .MJInfoBtn {
    width: 283px;
    height: 53px;
    top: 1316px;
    left: 530px;
    border-radius: 4px;
    gap: 10px;
    background: #ffffff;
    color: #0b4875;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;

    &:hover {
      color: #ffffff;
      background: #0b4875;
    }
  }

  .MJInfoBtn:focus {
    outline: none;
  }

  .MJpremint {
    color: var(--Navy, #0a4777);

    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.6px;
    text-align: left;
    /* 140.952% */
  }

  .MJLogo {
    width: 270px;
    height: 230px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scrolling-content {
  display: flex;
  animation: scroll 15s linear infinite;
}

.MJTitleLeft {
  color: rgba(10, 71, 119, 1);
  font-size: 21px;
  font-weight: 700;
  line-height: 29.6px;
}

.MJColla {
  padding: 50px 0px;
  width: 100%;
  max-width: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 240px;

  .MJCollaTitle {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 49px;
    font-weight: 700;
    text-align: center;
    color: #202020;
  }

  .MJCollaContent {
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #202020;
    line-height: 29.6px;
  }
}

.MJPartner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0px 0px 50px 0px;
}

.MJNft {
  min-height: 441px;
  margin: 0px 0px 80px 0px;
  padding: 40px 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(231, 230, 230, 1);
  border-radius: 15px;
  /* gap: 24px; */

  .MJNftLeft {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    font-size: 21px;
    font-weight: 400;
  }

  .MJNftRight {
    display: flex;
    flex-direction: column;
  }

  .MJNftButtons {
    background: #41a1c8;
    width: 210px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    color: #ffffff;
    border: none !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;

    &:hover {
      color: #41a1c8;
      background: #ffffff;
      outline: 1px solid #41a1c8;
    }
  }

  .MJNftButtons:focus {
    outline: none;
  }

  .MJNftContent {
    max-width: 420px;
    min-height: 148px;
    padding-bottom: 9px;
  }

  .MJNftButtonsContainer {
    display: flex;
    gap: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .MJPartner {
    display: unset !important;
    text-align: center !important;
  }

  .MJCollaTitle {
    display: flex;
    flex-direction: column !important;
  }

  .MJNft {
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .MJOfficeNameGradient {
    text-align: center;
  }

  .MJNftLeft {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 9px;
  }

  .MJNftContent {
    width: 100% !important;
    max-width: none !important;
  }

  .MJNftButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.MJOffice {
  min-height: 638px;
  padding: 0px 0px 40px 0px;
  /* width       : 70%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;

  .MJUtility {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }

  .MJOfficeLeft {
    width: 50%;
    gap: 48px;
    display: flex;
    flex-direction: column;
  }

  .MJOfficeInner {
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MJOfficeTitle {
    font-size: 49px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    min-height: 57px;
    color: #202020;
  }

  .MJOfficeName {
    width: 100%;
    color: #222222;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .MJOfficeContent {
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #202020;
    text-align: left;
  }

  .MJOfficeContentContainer {
    /* min-width: 500px; */
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
}

.MJOfficeNameGradient {
  background: linear-gradient(99deg,
      rgba(3, 65, 161, 0.78) 46.44%,
      #22b7bb 84.79%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  display: flex;
  padding-bottom: 9px;
  line-height: 56.9px;
  align-items: center;
}

.MJRoadMap {
  padding: 0;

  .MJRoadMapTitle {
    font-size: 49px;
    font-weight: 700;
    width: 220px;
    background: linear-gradient(103.56deg,
        rgba(3, 65, 161, 0.784314) 0%,
        #22b7bb 99.35%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .MJRoadMapQ1 {
    min-height: 321px;
    padding: 40px;
    border-radius: 15px;
    border: 1px solid rgba(231, 230, 230, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .MJRoadMapLeft {
    padding-top: 6%;
    display: flex;
    width: 15%;
    justify-content: center;
  }

  .MJRoadMapRight {
    padding-top: 28px;
    display: flex;
    width: 90%;
  }

  .MJRoadMapContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 80px;
  }

  .MJRoadMapQ1Ordinals {
    width: 100%;
    padding: 40px;
    gap: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 457px;
    border-radius: 15px;
    border: 1px solid rgba(231, 230, 230, 0.8);
    background: linear-gradient(103.14deg,
        rgba(3, 65, 161, 0.784314) -12.23%,
        #22b7bb 99.35%);
  }

  .ordinalsBtns {
    width: 97px;
    height: 53px;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
    color: rgba(4, 141, 193, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(4, 141, 193, 1);
      color: rgba(255, 255, 255, 1);
    }
  }

  .MJOrdinals {
    font-size: 21px;
    font-weight: 400;
    line-height: 29.6px;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 12px;
  }

  .MJRoadMapTwitter {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 312px;
    padding: 40px;
    border-radius: 15px;
    gap: 12px;
    border: 1px solid rgba(65, 161, 200, 1);
    transition: min-height 0.6s ease-in-out;
  }

  .MJRoadMapTwitterClosed {
    min-height: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    border-radius: 15px;
    gap: 12px;
    border: 1px solid rgba(65, 161, 200, 1);
    transition: min-height 0.6s ease-in-out;
  }

  .MJRoadMapQ2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 188px;
    padding: 40px;
    border-radius: 15px;
    gap: 12px;
    border: 1px solid rgba(231, 230, 230, 0.8);
  }

  .MJRoadMapQ3 {
    width: 100%;
    padding: 40px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid rgba(231, 230, 230, 0.8);
    transition: min-height 1s ease-in-out;
  }

  .MJRoadMapQ3Closed {
    min-height: 0;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    gap: 12px;
    border: 1px solid rgba(231, 230, 230, 0.8);
    transition: min-height 1s ease-in-out;
  }

  .MJRoadMapRightInner {
    display: flex;
    gap: 40px;
    width: 100%;
    flex-direction: column;
  }

  .roadMapTime {
    background: linear-gradient(103.14deg,
        rgba(3, 65, 161, 0.784314) -12.23%,
        #22b7bb 99.35%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .stayConnected {
    background: linear-gradient(103.14deg,
        rgba(3, 65, 161, 0.784314) -12.23%,
        #22b7bb 99.35%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    font-weight: 700;
    line-height: 32.81px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
  }

  .roadMapTitleOrdinal {
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
  }

  .roadMapOptionsOrdinal {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.1px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    height: 53px;
    display: flex;
    align-items: center;
  }

  .roadMapTitle {
    color: #202020;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .roadMapTitleMint {
    color: var(--White, #fff);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .rowContainerMint {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 53px;
  }

  .rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 53px;
  }

  .rowContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 53px;
  }

  .rowContainerSocial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
    min-height: 60px;
  }

  .rowContainernew {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .linkButton {
    background: #41a1c8;
    width: 224px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    color: #ffffff;
    border: none !important;

    &:hover {
      color: #41a1c8;
      background: #ffffff;
      outline: 1px solid #41a1c8;
    }
  }

  .linkButton:focus {
    outline: none;
  }

  .linkText {
    font-size: 21px;
    font-weight: 400;
    line-height: 29.6px;
  }

  .linkTextmint {
    border: none;
    border-radius: 4px;
    background: var(--White, #fff);
    padding: 16px 30px;

    color: var(--Blue, var(--Blue, #048dc1));
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* 140.952% */
  }

  .textRM {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(32, 32, 32, 1);
  }

  .text2 {
    text-align: left;
    color: rgba(32, 32, 32, 1);
    font-size: 18px;
    font-weight: 700;
    line-height: 24.1px;
  }

  .text4 {
    text-align: left;
    color: rgba(32, 32, 32, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }

  .textLink {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: rgba(32, 32, 32, 1);
    cursor: pointer;
    line-height: 24.1px;

    &:hover {
      color: #32b0dd;
    }
  }

  .signUpStyle {
    .form {
      margin: 0px !important;
      background: #f8f9fa !important;
    }

    .container {
      padding: 0px;
      display: flex;
    }

    #mc_embed_signup {
      background-color: #fff;
    }

    .parent {
      display: contents;
    }

    .child {
      margin: 8px 30px 8px 0px !important;
    }

    .child:first-child {
      margin-left: 0px;
    }
  }
}

.MJTeam {
  padding: 70px 0px;

  .MJTeamTitle {
    padding-bottom: 28px;
    font-size: 49px;
    font-weight: 700;
    width: 220px;
    background: linear-gradient(103.56deg,
        rgba(3, 65, 161, 0.784314) 0%,
        #22b7bb 99.35%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .MJTeamGrid {
    display: flex;
    flex-direction: row;
    gap: 27px;
    align-items: flex-start;
  }

  .MJTeamName {
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: #41a1c8;
    }
  }

  .MJTeamTitleInner {
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MJTeamContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

@media screen and (max-width: 1050px) {
  .MJInfoContainer {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: unset !important;
  }

  .MJTitleDis {
    font-size: 32px !important;
    white-space: normal !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 800px) {
  .rowContainerClaim {
    flex-direction: column !important;
    gap: 12px !important;
    padding-right: 10px !important;
  }

  .text3 {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 21.09px !important;
  }

  .linkText {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .rowContainerSocial {
    flex-direction: column !important;
    gap: 20px !important;
    padding: 20px 0px;
  }

  .rowContainer2 {
    display: flex;
    flex-direction: column !important;
    gap: 20px !important;
    width: 100%;
    align-items: flex-start !important;
    padding: 20px 0px 0px 0px !important;
  }

  .MJCollaContent {
    width: 100%;
    padding: 0px 20px;
    font-size: 21px;
    font-weight: 400;
    text-align: center;
    color: #202020;
  }
}

@media screen and (max-width: 600px) {
  .roadMapTitleOrdinal {
    font-size: 18px !important;
    line-height: 21.09px;
    width: 213px;
  }

  .MJRoadMapQ1 {
    gap: 12px !important;
  }

  .textRM {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.1px;
    text-align: left;
    white-space: nowrap;
  }

  .linkButton {
    width: 100%;
  }

  .MJOfficeNameGradient {
    text-align: left !important;
  }

  .MJNftLeft {
    display: flex !important;
    align-items: flex-start !important;
  }

  .MJLogo {
    width: 100%;
  }

  .MJInfoLeft {
    text-align: center !important;
  }

  .whoMJ {
    min-height: 312px;
    margin-bottom: 10px !important;
    margin-top: 30px !important;
  }

  .gifImg {
    width: 90%;
    height: 100%;
  }

  .MJInfoInner {
    width: 100% !important;
  }

  .MJInfoLeft {
    font-size: 18px !important;
    text-align: center !important;
  }

  .MJInfoInner {
    width: 100% !important;
  }

  .MJTitleBelow {
    width: 294.74px !important;
    height: 36.24px !important;
    left: 21.5px !important;
  }

  /* future plans */
  .MJOfficeTitle {
    font-size: 40px !important;
    text-align: center !important;
    max-width: 324px !important;
  }

  .MJOfficeInner {
    flex-direction: column !important;
    padding: 0 !important;
  }

  .MJOfficeName {
    font-size: 32px !important;
  }

  .MJOfficeContent {
    font-size: 21px !important;
  }

  .MJOffice {
    width: 100% !important;
  }

  .MJOfficeRight {
    display: flex !important;
    justify-content: center !important;
  }

  .MrJOfficeImg {
    width: 328px !important;
  }

  .MJOfficeLeft {
    width: 100% !important;
    padding: 0px 35px !important;
  }

  .MJOfficeNameGradient {
    font-size: 32px !important;
  }

  .MJOfficeImgTop {
    padding-bottom: 28px !important;
  }

  /* Road map */
  .MJRoadMap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MJRoadMapContainer {
    width: 100%;
    padding: 0px 20px;
    gap: 12px;
  }

  .rowContainer {
    flex-direction: column !important;
    gap: 12px;
    align-items: flex-start !important;
  }

  .linkTextAccess {
    font-size: 18px !important;
    white-space: nowrap;
    line-height: 21.09px !important;
  }

  .socialGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .roadMapTitle {
    font-size: 24px !important;
  }

  .MJRoadMapLeft {
    padding-top: 20% !important;
  }

  /* Team */
  .MJTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MJTeamTitle {
    display: flex;
    justify-content: center;
  }

  .MJTeamGrid {
    width: 100%;
    gap: 7px !important;
    padding: 0px 30px !important;
  }

  .MJTeamContainer {
    align-items: flex-start !important;
  }

  .MJTeamName {
    white-space: break-spaces !important;
  }

  .MJNftButtonsContainer {
    width: 100%;
    display: contents !important;
  }
}

.scroller {
  max-width: 100%;
  mix-blend-mode: darken;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.logoImageOurMj {
  height: 40px !important;
  width: 100% !important;
}

.scroller {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 35s) var(--_animation-direction, forwards) linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

.scroller {
  --_animation-duration: 25s;
  --_animation-direction: forwards;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.text3 {
  color: rgba(32, 32, 32, 1);
  font-size: 28px;
  font-weight: 700;
  line-height: 32.81px;
}

.text4 {
  color: rgba(158, 158, 158, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
}

.rowContainerClaim {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rowContainerClaim2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MJContentLeft {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.1px;
  text-align: left;
  color: rgba(32, 32, 32, 1);
}

.MJContentCenter {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.1px;
  text-align: center;
  color: rgba(32, 32, 32, 1);
}

.readmore {
  color: var(--Blue, #048dc1);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
}

.MJCollaTitleMobile {
  color: var(--Black, #202020);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 27px;
}

@media screen and (max-width: 450px) {
  .MJNftLeft {
    display: flex !important;
    align-items: flex-start !important;
  }

  .MJLogo {
    width: 100%;
  }

  .MJInfoLeft {
    text-align: center !important;
  }

  .whoMJ {
    min-height: 312px;
    margin-bottom: 10px !important;
    margin-top: 30px !important;
  }

  .gifImg {
    width: 90%;
    height: 100%;
  }

  .MJInfoInner {
    width: 100% !important;
  }

  .MJInfoLeft {
    font-size: 18px !important;
    text-align: center !important;
  }

  .MJInfoInner {
    width: 100% !important;
  }

  .MJTitleBelow {
    width: 294.74px !important;
    height: 36.24px !important;
    left: 21.5px !important;
  }

  /* future plans */
  .MJOfficeTitle {
    font-size: 40px !important;
    text-align: center !important;
    max-width: 324px !important;
  }

  .MJOfficeInner {
    flex-direction: column !important;
    padding: 0 !important;
  }

  .MJOfficeName {
    font-size: 32px !important;
  }

  .MJOfficeContent {
    font-size: 21px !important;
  }

  .MJOffice {
    width: 100% !important;
  }

  .MJOfficeRight {
    display: flex !important;
    justify-content: center !important;
  }

  .MJOfficeLeft {
    width: 100% !important;
    padding: 0px 35px !important;
  }

  .MJOfficeImgTop {
    padding-bottom: 28px !important;
  }

  /* Road map */
  .MJRoadMap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MJRoadMapContainer {
    width: 100%;
    padding: 0px 20px;
    gap: 12px;
  }

  .socialGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .roadMapTitle {
    font-size: 24px !important;
  }

  .MJRoadMapLeft {
    padding-top: 20% !important;
  }

  /* Team */
  .MJTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MJTeamTitle {
    display: flex;
    justify-content: center;
  }

  .MJTeamGrid {
    width: 100%;
    gap: 7px !important;
    padding: 0px 30px !important;
  }

  .MJTeamContainer {
    align-items: flex-start !important;
  }

  .MJTeamName {
    white-space: break-spaces !important;
  }

  .MJNftButtonsContainer {
    width: 100%;
    display: contents !important;
  }

  .MJTitleDis {
    font-size: 28px !important;
    line-height: 32.81px !important;
    text-align: left;
    color: var(--Black, #202020);
  }

  .MJInfoBtn {
    width: 248px !important;
    line-height: 21.09px;
  }

  .MJCollaTitle {
    font-size: 28px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
    line-height: 32.81px;
  }

  .MJOfficeNameGradient {
    font-size: 32px;
    font-weight: 700;
    line-height: 37.5px;
    text-align: left;
  }

  .MJNftContent {
    font-size: 21px;
    font-weight: 400;
    line-height: 29.6px;
    text-align: left;
  }

  .MrJOffice {
    width: 100% !important;
    height: 100% !important;
    padding-top: 18px;
  }

  .signUpStyle {
    #mc_embed_signup {
      width: 90% !important;
    }
  }

  .stayConnected {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 21.09px !important;
    text-align: left;
  }

  .stayStyle {
    width: 129px;
  }

  .MJRoadMapTwitter {
    padding: 40px !important;
  }

  .MJRoadMapTwitterClosed {
    padding: 40px !important;
  }

  .MJRoadMapQ2 {
    padding: 40px !important;
  }

  .MJRoadMapQ3Closed {
    padding: 40px !important;
  }

  .MJRoadMapQ3 {
    padding: 40px !important;
  }

  .wavingMJ {
    width: 100% !important;
    height: 100% !important;
  }

  .metaverseMJ {
    width: 100% !important;
    height: 100% !important;
  }

  .journalerAi {
    width: 100% !important;
    height: 100% !important;
  }
}