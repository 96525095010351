.footerContainer {
  background-color: #F0F8FC;
  padding         : 5%;
  color           : #254674;
}

.bgmobile {
  background-color: #F0F8FC;
}

.underlineLabel {
  color             : #0B4875;
  font-size         : 16px;
  font-style        : bold;
  font-weight       : 500;
  line-height       : normal;
  /* text-decoration: underline; */
}

.footerLi {
  padding-top: 5px;
  text-align : left;
  height     : auto;
}

.list-links {
  list-style-type: none;
  padding-left   : 0;
}

.list-link {
  font-size: 14px;
  color    : #707070;
}

.footerDropdownBtn {
  background-color: transparent !important;
  border          : none !important;
  color           : #254674 !important;
  box-shadow      : none !important;
  font-size       : 14px;
}

.custom-dropdown {
  /* border-radius: 20px; */
}

.linksStyle {
  color: #254674;
}

.linksStyle:hover {
  color: #254674;
}

.featuredDownload {
  color    : #000000;
  font-size: 16px;
}

.borderBottom {
  border-bottom: 1px solid #707070;
}

.col-footer {
  width: 120px;
}

.footerTerm {
  text-align: right;
}

.containLocation {
  padding-top : 15px;
  padding-left: 25px;
}

.footerTerm {
  font-size      : 12px;
  color          : gray;
  text-decoration: underline;
}

.footerTermhr {
  color          : gray;
  text-decoration: underline;
  padding-left   : 5px;
}

.footerMobile {
  background: white;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .underlineLabel {
    font-size: 14px;
  }

  .hideImage {
    display: none;
  }

  .linksStyle {
    font-size: 12px;
  }

  .footerDropdownBtn {
    text-align  : left;
    width       : 90%;
    padding-left: 30px;
    color       : #000000 !important;
  }

  .footerTerm {
    padding-top: 15px;
    text-align : center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 860px) {
  .underlineLabel {}
}

@media screen and (max-width: 768px) {

  .footerContainer {
    background-color: #fff;

    padding: 0px 0px 30px 0px;

  }

  .logoDiv {
    display        : flex;
    justify-content: center !important;
  }
}