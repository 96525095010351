.activePoint {
  position: absolute;
  margin-top: 38px;
  transition: 0.2s ease-in-out;
  margin-right: 10px;
  z-index: 2;
}

.inactivePoint {
  position: absolute;
  margin-top: 38px;
  transition: 0.2s ease-in-out;
  margin-right: 10px;
  z-index: 2;
}

.roadActive {
  z-index: 1;
  border-left: 10px solid;
  border-image-source: linear-gradient(100.97deg,
      rgba(3, 65, 161, 0.784314) 1.25%,
      #22b7bb 86.58%);
  border-image-slice: 1;
  width: 10px;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.roadDisabled {
  z-index: 0 !important;
  border-left: 10px solid rgba(237, 237, 237, 1);
  border-image-slice: 1;
  width: 10px;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: height 0.2s ease-in-out;
}
