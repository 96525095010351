.policyPageWrapper {
  background: #f7f7f7;
  padding-top: 130px;
  padding-bottom: 20px;
}

.titleBorder {
  padding-bottom: 15px;
  border-bottom: 1px solid #3081aa;
}
.subtitle{
  margin-left: 5%;
}