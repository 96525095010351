.spcurrencies {
  background-color: #368fb7;
}

.card-body-currency {
  padding-top: 15px;
}

.SupportedCurHeader {
  padding: 130px 0;
}

.pricingSc {
  color: #f7f7f7;
}

.globalHeaderTitleSc {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
}

.currencyCard {
  box-shadow: 0px 4px 8px #84848429;
  border-radius: 10px;
  height: 150px;
}

.currencyLabel {
  color: #707070;
}

.searchCrypto {
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000029;
  border: 0;
  height: 40px;
  width: 100%;
  margin-top: 20px;
}

.searchCrypto:focus {
  outline: none;
}

.searchCrypto.active {
  border: 0;
}

.filter {
  transform: rotate(-90deg);
  color: #717071;
  font-size: 20px;
  right: 31%;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.filter-open {
  color: #717071;
  transform: rotate(90deg);
  font-size: 20px;
  right: 31%;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.inputContainer {
  position: relative;
}

.custom-dropdown {
  position: relative;
}

.dropdown-list {
  display: none;
}

.dropdown-list-open {
  display: block;
  width: 12%;
  position: absolute;
  right: 31%;
  background-color: #fff;
  z-index: 1;
  border-radius: 5px;
  max-height: 250px;
  overflow: overlay;
  cursor: pointer;
}

.dropdown-item {
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdown-item input[type="radio"] {
  display: flex;
  align-items: flex-start;
  margin-right: 15px;
  box-sizing: border-box;
  appearance: none;
  border: 1px solid #a49fa1;
  border-radius: 5px;
  width: 20px;
  height: 15px;
}

.checkbox {
  cursor: pointer;
}

.token-circle {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  color: #a49fa1;
  font-weight: 500;
  margin-left: 2%;
}

.token-label {
  opacity: 0.8;
  font-size: 11px;
}

/* scroll bar css */
::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* responsive for 770 and under */
@media only screen and (max-width: 769px) {
  .filter {
    color: #717071;
    transform: rotate(-90deg);
    font-size: 20px;
    right: 5%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
  }

  .filter-open {
    color: #717071;
    transform: rotate(90deg);
    font-size: 20px;
    right: 5%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
  }

  .dropdown-list-open {
    display: block;
    width: 35%;
    position: absolute;
    right: 10%;
    background-color: #fff;
    z-index: 1;
    border-radius: 5px;
    max-height: 250px;
    overflow: overlay;
    cursor: pointer;
  }
}

input[type="radio"]:checked {
  background: #333;
}