.fullAboutUs {
  background: transparent linear-gradient(180deg, #074673 0%, #2677A0 19%, #368FB7 30%, #5EA6C6C1 41%, #83BBD488 49%, #AAD2E34C 57%, #FFFFFF32 77%, #FFFFFF17 100%) 0% 0% no-repeat padding-box;
  overflow: hidden;
}

.h1BoldUs {
  font-size: 32px;
  font-weight: bold;
  color: #FFFFFF;
}

.previousBtn {
  /* position: absolute;
  left: 20%; */
  /* padding-right: 100px; */
}

.image-xero {
  /* float:left;width:50%;height:100%; */
  /* right: -900px; */
  /* position: absolute; */
}

.image-aemplus {

  margin-left: -100%;
}

.aboutUsSub {
  /* font-size: 20px; */
  font-weight: bold;
}

.videoYoutube {
  padding-top: 5%;
}

.ourMission {
  background-color: #1F6C97;
}

.whyaem {
  background-color: #41A1C8
}

.paddingwhyaem {
  padding-top: 5%;
  padding-bottom: 5%;
}

.meetteamAbout {
  background: #074673 0% 0% no-repeat padding-box;
}

.title_export-about {
  padding-top: 15%;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
}

.subtitle_export-about {
  color: #FFFFFF;
  font-size: 25px;
  padding-bottom: 15%;
  padding-right: 100px;
}

.title_export-about-mission {
  padding-top: 5%;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
}

.subtitle_export-about-mission {
  color: var(--Grey, #9E9E9E);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.1px;
}

.nextBtn {
  /* position: absolute;
  right: 30%; */
}

.imageAbout {
  display: flex;
  width: 70%;
}

.aboutUsHeader {
  padding: 130px 0 80px;
}

.lightGreyBg {
  background-color: #f7f7f7;
}

.moreStoryBtn {
  padding: 15px 10px;
  border-radius: 30px;
  /* border       : 2px solid ; */
  font-weight: bold;
  width: 256px;
  color: white;
  font-size: 15px;
  background-color: #008CC1;
}


.missionSectionContainer {
  padding: 150px 0 80px;
}

.ourMissionSubtitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-sf-pro-display);
  color: #707070;
}

.ourMissionTitle {
  color: #094674;
  font-size: 36px;
  font-weight: 800;
}

.ourMissionSubTop {
  color: #094674;
  font-size: 20px;
  font-weight: bold;
}

.ourMissionSubBot {
  color: #094674;
  font-size: 20px;
  /* font-weight: bold; */
}

.footer.content>img,

.footer.content>h1 {
  color: #094674;
  font-size: 100px;
  display: inline-block;
  font-weight: bold;
}

.imageLeft {
  height: 70px;
  float: left;
  margin-top: 20px;
}

.imageRight {
  height: 70px;
  float: right;
  margin-top: 20px;
}


.textdesBrief {
  font-size: 20px;
  letter-spacing: 0px;
  color: #094674;
  opacity: 1;
}

.textdesBriefFooter {
  font-size: 25px;
  letter-spacing: 0px;
  color: #094674;
  opacity: 1;
  text-align: left;
}

.aboutUsPageBg {
  /* background-image: url(../../assets/about/story_bg3.svg); */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutUsPageBgPartTwo {
  /* background-image: url(../../assets/about/story_bg4.svg); */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.roadmap {
  width: 100%;
  height: 950px;
  background-image: linear-gradient(#074673, #41A1C8);
}

.roadmapNew {
  background: #EDEDED;
}

.visionSectionContainer {
  /* background: transparent linear-gradient(270deg, #094674 0%, #05233A 100%) 0% 0% no-repeat padding-box; */
  /* background-image: url(../../assets/about/version_bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  padding: 150px 0 150px;
}

.sectionTitle {
  font-size: 70px;
  font-weight: 800;
  color: #094674;
}

.sectionTitleRoadmap {
  padding-top: 5%;
  font-size: 70px;
  font-weight: 800;
  color: #FFFFFF;
  text-align: center;
}

.futureMissionTitle {
  margin-top: 10%;
  margin-bottom: 10%;
  color: #0f4b73;
  text-align: left;
}

.fmdiagramImg {
  width: 500px;
}

.futurnMissionContainer {
  display: flex;
  flex-direction: row;
}

.roadmapImg {
  /* width: 70%; */
  height: auto;
}

.carousel {
  padding-top: 5%;
  width: 100%;
  height: 70% !important;
  /* background-color: red; */

}

.yearRoadmap {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 80px;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  font-weight: bold;
}

.subtileRoadmap {
  color: #000000;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.dot {
  height: 35px;
  width: 35px;
  background: transparent linear-gradient(180deg, #074673 0%, #41A1C8 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-block;
}

.dot1 {
  margin: 5px 5px 5px 5px;
  height: 25px;
  width: 25px;
  background: transparent linear-gradient(180deg, #094674 0%, #05233A 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-block;

}

.mytextdivleft {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20%;
  /* margin-left: 5%; */
}

.mytextdivright {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20%;
  margin-left: 5%;
}

.mytexttitle {
  flex-grow: 0;
}

.divider1 {
  flex-grow: 0.5;
  height: 1px;
  border: 2px dashed #FFFFFF;
}

.divider {
  flex-grow: 3;
  height: 1px;
  border: 2px dashed #FFFFFF;
}

.divDetail {
  width: 615px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  overflow: auto;
}

.divDetail1 {
  margin-top: 25px;
  width: 615px;
  height: 350px;
  background: #FFFFFF;
  border-radius: 10px;
  opacity: 1;
}

.divDetail2 {
  margin-top: 25px;
  width: 615px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-left: 15%;
}

.divDetail3 {
  margin-top: 25px;
  width: 615px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  float: right;
}

.contentDetail {
  margin-top: 15px;
  color: #002F52;
  font-size: 17px;
  font-weight: bold;
}

.centertextdesBriefFooter {
  width: 80%;
  margin-left: 15%;
}


/* .carousel-control-prev{
  margin-left: 8%;
}
.carousel-control-next{
  margin-right:12%;
} */
@media only screen and (max-width: 860px) {
  .aboutUsHeader {
    padding: 104px 40px 80px 40px;
  }

  .imageAboutFill {
    width: 656px;
  }

  .aboutUsHeaderTitle {
    font-size: 35px;
    font-weight: bold;
  }

  h1 {
    font-size: 30px;
  }

  .fmDiagramImg {
    width: 80%;
  }

  .futurnMissionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .titleDiv {
    width: 100%;
  }

  .footer.content>h1 {
    font-size: 50px;
  }

  .sectionTitleRoadmap {
    font-size: 35px;
  }

  .yearRoadmap {
    font-size: 25px;
  }

}



@media screen and (max-width: 600px) {
  .visionSectionContainer {
    padding: 50px 0;
  }

  .missionSectionContainer {
    padding: 0 0 80px;
  }

  .h1BoldUs {
    font-weight: bold;
    font-size: 16px;
  }

  .aboutUsSub {
    font-size: 20px;
    font-weight: bold;
  }

  .imageAboutFill {
    width: 100%;
  }

  .imageLeft {
    height: 25px;
  }

  .imageRight {
    height: 25px;
  }

  .textdesBrief {
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .moreStoryBtn {
    width: 150px;
    font-size: 15px;
  }

  .ourMissionTitle {
    font-size: 12px;
  }

  .ourMissionSubTop {
    font-size: 14px;
  }

  .ourMissionSubBot {

    font-size: 14px;
  }

  .sectionTitle {
    font-size: 27px;
  }

  .futureMissionTitle {
    font-size: 29px;
  }

  .textdesBriefFooter {
    font-size: 14px;
    width: 80%;
    margin-left: 10%;

  }

  .btnGroup_greenBgBtn_mailchip {
    width: 120px;
    font-size: 12px;
  }

  .sectionTitleRoadmap {
    font-size: 25px;
  }

  .yearRoadmap {
    font-size: 15px;
  }

  .subtileRoadmap {
    font-size: 15px;
  }

  .roadmap {
    height: 300px;
  }

  .title_export-about {
    font-size: 16px;

  }

  .subtitle_export-about {
    font-size: 14px;
  }

  .title_export-about-mission {
    font-size: 16px;
  }

  .subtitle_export-about-mission {
    font-size: 14px;
  }

}