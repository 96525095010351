/* ------- Two buttons: -------- */
.startBtn {
  padding: 15px 10px;
  background-color: #3081aa;
  color: #ffffff;
  border-radius: 30px;
  font-weight: bold;
  margin-right: 30px;
  width: 256px;
}

.startLink {
  color: #ffffff;
  text-decoration: none;
}

.startLink:hover {
  color: #ffffff;
  text-decoration: none;
}

.tryXeroBtn {
  border-radius: 30px;
  border: 2px solid #3081aa;
  font-weight: bold;
  width: 256px;
}

.xeroLink {
  color: #3081aa;
  text-decoration: none;
}

.xeroLink:hover {
  color: #3081aa;
  text-decoration: none;
}

.title {
  font-size: 49px;
  font-weight: bold;
}

/* normal 20px font text */
.text {
  font-size: 20px;
}

.text-color {
  color: #0f4b73;
}

.lightText {
  color: #707070;
}

/* about xero */
.bold_14_font {
  font-size: 14px;
  font-weight: bold;
}

.normal_14_font {
  font-size: 14px;
  padding: 4px;
  height: fit-content;
}

.card_title {
  color: #0f4b73;
  font-size: 39px;
  font-weight: bold;
}

.border_bottom {
  text-decoration: none;
  border-bottom: 1px solid #0f4b73;
}

.demoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 2;
  margin-top: -250px;
}

.cryptoCard {
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
  height: 270px;

  opacity: 1;

}

.eachCrypto {
  height: 125px;
  width: 125px;
}

/* journaler can help  */
.helpWrapper {
  padding: 300px 0 20px 0;
  margin-top: -300px;
}

.subTitle {
  color: #707070;
  font-size: 28px;
  text-align: right;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.list {
  list-style-type: none;
  font-size: 20px;
}

/*  about xero:  */
.aboutXeroDiv {
  display: flex;
  flex-direction: row;
}

.imgDiv {
  padding-right: 20px;
}

.blockchainText {
  font-size: 14px;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .eachCrypto {
    height: 100px;
    width: 100px;
  }  
}

@media only screen and (max-width: 768px) {
  .demoWrapper {
    margin-top: -180px;
  }
  .img {
    width: 100%;
  }

  /* ---- help: */
  .helpWrapper {
    padding: 200px 0 10px 0;
    margin-top: -180px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
  }

  .subTitleDiv {
    padding-left: 40px;
  }
  .subTitle {
    text-align: left;
  }

  /* --- about xero: */
  .aboutXeroDiv {
    display: flex;
    flex-direction: column;
  }

  .imgDiv {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .cryptoCard {
    /* width: 90%; */
  }
}
