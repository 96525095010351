.customNavbar {
  padding: 10px 40px;
  /* background   : white; */
  background-color: white;
}

.detailToolbar {
  background: var(--Dark-Navy, #031a3a);
  box-shadow: 0px 74px 100px 0px rgba(0, 0, 0, 0.14);
  padding: 12px;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 66px;
  margin-right: 12px;
  padding-bottom: 45px;
}

.group-item {
  color: var(--White, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 16px;
  text-align: left;
}

.group-item-container {
  color: var(--White, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px;
  text-align: left;
}

.iconCaret {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.mobileContent {
  background-color: #071a3b;
  padding-top: 12px;
  padding-bottom: 20px;
}

.userLogin {
  padding-right: 24px;
}

.sign-in {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 100px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #8064a2 !important;
}

.checkMobile {
  visibility: hidden;
  border-radius: 0px 50px 50px 0px;
  background-color: white;
  position: fixed;
  top: 50px;
  transition-duration: 4s !important;
  transition-delay: 2s !important;
  outline: none;
}

.dropdown-menu {
  border-radius: 15px;
  min-width: 200px;
  left: -60px;
  padding-top: 20px;
}

.activeDivBottom {
  border-radius: 0px 0px 8px 8px;
  background: rgba(237, 237, 237, 0.1);
}

.dropdown-menu:before,
.dropdown-menu:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
}

.sidebar.show {
  left: 0;
  z-index: 9999 !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -110%;
  width: 100%;
  height: 100%;
  transition: left 0.4s ease-in-out;
}

.sidebar .d-flex {
  justify-content: space-between;
}

.dropdown-menu:before {
  left: 92px;
  border: 11px solid transparent;
  border-bottom-color: #ddd;
}

.dropdown-menu:after {
  left: 93px;
  border: 10px solid transparent;
  border-bottom-color: #fff;
}

.showToggle {
  /* height: 100px; */
  width: 0;
  position: fixed;
  z-index: 1;
  top: 50px;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  /* background   : #000000; */
  /* height       : 100%; */
}

.menuIcon {
  vertical-align: text-top;
  width: 25px;
}

.nav-item {
  margin-right: 30px;
}

.nav-link {
  color: #0b4875;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .selected {
  font-weight: bold;
  color: #ffffff;
} */

.nav-link:hover {
  color: #094674;
}

.nav-link-mobile {
  /* color: #094674; */
  color: #008cc1;
  /* font-weight: 500; */
  font-size: 16px;
}

.dropdown-toggle {
  font-size: 18px;
  background-color: transparent;
  border: none;
}

.dropdown-toggle::after {
  vertical-align: middle;
  font-size: 15px;
}

.dropdown-toggle:focus {
  background-color: transparent;
  border: none;
  outline: none;
}

.signupLink {
  background-color: #41a1c8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  min-height: 53px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #41a1c8;
    outline: 1px solid #41a1c8;
  }
}

.loginLink {
  border-radius: 30px;
  border: 2px solid #008cc1;
  color: #008cc1;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 20px;
  text-decoration: none;
  margin: 0;
}

.loginLink:hover {
  border: 2px solid #32b0dd;
  color: #32b0dd;
}

.signupLink:hover {
  background-color: #32b0dd;
  border: 1px solid #32b0dd;
  color: white;
}

/* -------- navbar toggler */
.custom-toggler.navbar-toggler {
  /* border-color: #2F8BF7; */
  float: right;
}

.nabbarTitle {
  height: 70px;
  text-align: center;
  font-size: 25px;
  color: #478dc1;
  border-bottom: 1px solid #efefef;
}

.custom-toggler .navbar-toggler-icon {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(47, 139, 247)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.tranNav {
  height: auto;
  background: white;
}

/* -------- dropdown menu */
.custom-dropdown {
  /* border: 0; */
  box-shadow: 0px 3px 6px #00000029;
}

.dropdown-item {
  padding-left: 30px;
  font-size: 18px;
  color: #0b4875;
  font-weight: 400;
}

.dropdown-item_header {
  padding-left: 30px;
  color: var(--Blue, var(--Blue, #048dc1));

  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.6px;
  /* 140.952% */
}

.lastItem {
  border-bottom: 0;
  padding-bottom: 20px;
}

.signInJourBtn {
  border-bottom: 0;
  background-color: #074673;
  color: white;
  margin-bottom: -10px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 3px 6px #00000029;
  height: 50px;
  padding-top: 13px;
}

.signInJourBtn:hover {
  background-color: #0e5385;
  color: white;
}

.dropdown-item.active,
.dropdown-item.focus,
.dropdown-item:focus {
  background-color: #f7f7f7;
  color: #094674;
}

.logoNav {
  margin-left: 10px;
  width: 80px;
}

.logoNavMobile {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  /* width   : 80px; */
}

.clsBtn {
  width: 100%;
  height: 60px;
}

.clsTitlebtn {
  color: #008cc1;
  background: white;
}

.clsTitlebtn_mobile {
  background-color: #478dc1;
}

ul {
  list-style-type: none;
  margin-left: 0px;
  margin-bottom: 0px;
}

li {
  height: 55px;
  /* border-bottom: 1px solid #00000029; */
  text-align: center;
}

.btn-secondary {
  border-color: none !important;
  border: none !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #6ecbce !important;
}

.detailToolbar-color {
  color: #254674;
  font-weight: bold;
}

.icon-faCaretRight {
  height: 16px;
  width: 16px;
  color: #fff;
  padding: 2px;
}

.icon-faBars {
  height: 25px;
  width: 25px;
  color: #000000;
}

.textSubmenu {
  color: #2e5386;
}

.headerSubmenu {
  background-color: #f7f7f7;
  color: #254674;
  font-weight: bold;
}

.btn-back {
  background-color: #2e5386;
  color: white;
  height: 60px;
  width: 100px;
}

.signInTo {
  width: 90%;
  height: 60px;
  background-color: #2e5386;
  color: white;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .desktop {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .checkMobile {
    /* z-index: 100;
   
    border-radius: 0px 50px 50px 0px;
    background-color: white;
    position: fixed;
    top: 50px;
    transition-duration: 4s !important;
    transition-delay: 2s !important;
    outline: none; */

    visibility: visible;
  }

  .logoNav {
    margin-left: 10px;
    /* width   : 80px; */
  }

  .clsBtn {
    border-bottom: 1px solid #00000029;
  }
}

/* @media  768- 994 : fix navs overlap */
@media screen and (min-width: 768px) and (max-width: 1154px) {
  .checkMobile {
    /* z-index: 100;
   
    border-radius: 0px 50px 50px 0px;
    background-color: white;
    position: fixed;
    top: 50px;
    transition-duration: 4s !important;
    transition-delay: 2s !important;
    outline: none;
    height: 70px; */

    visibility: visible;
  }

  .desktop {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .customNavbar {
    padding: 10px 10px 10px 10px;
  }

  .nav-item {
    font-size: 21px;
    margin-right: 10px;
  }

  .nav-link {
    font-size: 21px;
    color: "#cc0000";
  }

  .loginLink {
    font-size: 10px;
    padding: 5px 5px;
    text-decoration: none;
  }

  .dropdown-item {
    font-size: 15px;
  }

  .logoNav {
    /* margin-left: 10px; */
    width: 50px;
  }

  .dropdown-menu {
    background: #6ecbce;
  }

  .clsBtn {
    border-bottom: 1px solid #00000029;
  }
}

.show>.showAboutUs {
  max-height: 800px;
  visibility: visible;
}

.showAboutUs {
  display: block;
  max-height: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.activeDivTopOpen {
  min-height: 0px;
}

.activeDivTop {
  border-radius: 8px 8px 0px 0px;
  background: rgba(237, 237, 237, 0.1);
}

.tab-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.slide-in {
  max-height: 1000px;
}

.slide-out {
  max-height: 0;
}