.networkHeader {
    background: var(--Dark-Navy, #031A3A);
}

.logoContainer {
    margin-top: -80px;
    padding   : 0 100px;
}


.headerTitle {
    padding-top: 100px;
    text-align : center;
    font-size  : 49px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;

    background             : linear-gradient(100deg, rgba(3, 65, 161, 0.78) 17.71%, #22B7BB 74.19%);
    background-clip        : text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subeaderTitle {
    color         : var(--White, #FFF);
    text-align    : center;
    font-size     : 24px;
    font-style    : normal;
    font-weight   : 500;
    line-height   : 33.1px;
    padding-bottom: 100px;
    /* 137.917% */
}

.headerPartner {
    padding-top            : 80px;
    text-align             : center;
    font-size              : 40px;
    font-style             : normal;
    font-weight            : 700;
    line-height            : 56.9px;
    /* 142.25% */
    background             : linear-gradient(100deg, rgba(3, 65, 161, 0.78) -12.68%, #22B7BB 80%);
    background-clip        : text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
    .headerPartner {
        font-size: 30px;
    }
}