.newsListHeader {
  padding: 130px 0 160px;
  /* margin-bottom: -50px; */
}

.newsHeaderImgBg {
  background-color: #00000029;
  border-radius: 50px 50px 0 0;
  /* height: 390px; */
}

.newsContentBg {
  /* background-color: #f7f7f7; */
  margin-top: -100px;
}

.newsContent {
  /* border-radius: 50px 50px 0 0; */
  background-color: #f7f7f7;
  padding: 50px 30px;
}

.newsContentFooter {
  background: transparent linear-gradient(180deg, #094674 0%, #05233A 100%) 0% 0% no-repeat padding-box;
  height: 75px;
  /* width: 100%; */
  color: white;
}