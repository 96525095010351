.headerTitleWhite {
    color      : #FFF;
    font-size  : 49px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 50px;
}

.newIntegration {
    padding-top: 50px;
}

.seamless {
    background             : linear-gradient(100deg, rgba(3, 65, 161, 0.78) -12.68%, #22B7BB 35.79%);
    background-clip        : text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
}

.crypto {
    color      : #000;
    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
}

.subtitleIntegrate {
    color      : var(--Black, #202020);
    font-size  : 21px;
    font-style : normal;
    font-weight: 500;
    line-height: 29.6px;
}

.colData {
    max-width  : 300px;
    min-height : 185px;
    border     : 1px solid var(--Medium-grey, rgba(222, 222, 222, 0.80));
    align-items: center;
}

.colTitle {
    padding-top: 10px;
    color      : var(--Black, #202020);
    font-size  : 21px;
    font-style : normal;
    font-weight: 500;
    line-height: 29.6px;
    text-align : center;
    /* 140.952% */
}

.linkdetailCol {
    padding-top: 10px;
    color      : var(--Grey, #9E9E9E);

    font-size  : 18px;
    font-style : normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 600px) {

    .colData {
        min-width: 170px;
    }
}