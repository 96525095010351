/* team page css */
.teamHeader {
  padding-top: 130px;
}

.meetteam {
  text-align: center;
  background: var(
    --Text-gradient,
    linear-gradient(103deg, rgba(3, 65, 161, 0.78) -12.23%, #22b7bb 99.35%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.teamHeader-card {
  padding: 30px 0;
}

.team-container {
  margin-top: -20px;
}

.teamTitle {
  color: #707070;
  font-size: 18px;
}

.teamDes {
  font-size: 12px;
  overflow: scroll;
  height: 400px;
}

.teamDes::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.team-page-title {
  margin-top: -70px;
  padding-bottom: 250px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  padding-left: 3.5rem;
  color: rgba(255, 255, 255, 1);
}

.team-label {
  color: #fff;
  font-weight: 600;
  font-size: 25px;
}

.team-member {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
}

.title-overlay {
  position: absolute;
  top: 55%;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
}

.title-overlay-with-role {
  position: absolute;
  top: 45%;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
}

.team-member:hover .title-overlay {
  opacity: 1;
}

.team-member:hover .title-overlay-with-role {
  opacity: 1;
}

.team-name {
  cursor: pointer;
  color: #2c729a;
  font-weight: 700;
  font-size: 15px;
  text-decoration: underline;
  white-space: nowrap;
}

.team-title {
  color: #2c729a;
  font-weight: 500;
  font-size: 9px;
}

.team-role {
  color: #2c729a;
  font-weight: 700;
  font-size: 9px;
  font-style: italic;
}

.team-member:hover img {
  background-color: white;
  mix-blend-mode: screen;
  opacity: 0.7;
}

/* Flip card */
.flip-box {
  background-color: transparent;
  /* width: 300px; */
  height: 470px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  border-radius: 20px;
  border: 1px solid #e4e4e4;
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  text-align: center;
}

.flip-box-back {
  padding: 10px;
  transform: rotateY(180deg);
}

/* Team landing page css section */
.bg-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 2500px;
  width: 1100px;
}

.landingPageContainer {
  padding-top: 200px;
  max-height: 1500px;
  max-width: 1200px;
  overflow-y: overlay;
  overflow-x: hidden;
}

.landingPageContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Responsive */
@media screen and (max-width: 1100px) and (min-width: 1000px) {
  .landingPageContainer {
    overflow-x: overlay;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .teamDes {
    font-size: 11px;
  }
}

/* Team Avatar CSS */
/* Management & Operation */
.team-circle {
  position: relative;
  bottom: 150px;
  left: 80px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.jakub {
  position: relative;
  border-radius: 50%;
  bottom: 440px;
  left: 290px;
  border: none;
  width: 200px;
  height: 200px;
}

.Matthew {
  position: relative;
  border-radius: 50%;
  bottom: 490px;
  left: 440px;
  border: none;
  width: 144px;
  height: 144px;
}

.Pamela {
  position: relative;
  border-radius: 50%;
  bottom: 780px;
  left: 490px;
  border: none;
  width: 160px;
  height: 160px;
}

.Linh {
  position: relative;
  bottom: 808px;
  left: 585px;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  border: none;
}

/* Marketing & Design */
.team-circle-marketing {
  position: relative;
  bottom: 890px;
  left: 835px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.Leanne {
  position: relative;
  border-radius: 50%;
  bottom: 490px;
  left: 626px;
  border: none;
  width: 13rem;
  height: 13rem;
}

.Serin {
  position: relative;
  border-radius: 50%;
  bottom: 515px;
  left: 740px;
  width: 11rem;
  height: 11rem;
  border: none;
}

.Peter {
  position: relative;
  border-radius: 50%;
  bottom: 708px;
  left: 575px;
  width: 10rem;
  height: 10rem;
  border: none;
}

.Dima {
  position: relative;
  bottom: 998px;
  left: 520px;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  border: none;
}

/* Tech Team */
.team-circle-tech {
  position: relative;
  bottom: -200px;
  left: 500px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.FDS {
  position: relative;
  border-radius: 50%;
  left: 740px;
  border: none;
  width: 170px;
  height: 170px;
}

.techTeam {
  position: relative;
  border-radius: 50%;
  bottom: 15px;
  left: 658px;
  border: none;
  width: 186px;
  height: 186px;
}

.Abhisaran {
  position: relative;
  border-radius: 50%;
  bottom: 1070px;
  left: 535px;
  border: none;
  width: 12rem;
  height: 12rem;
}

/* Advisor */
.team-circle-advisor {
  position: relative;
  bottom: 1800px;
  left: 375px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.Krzysztof {
  position: relative;
  border-radius: 50%;
  bottom: 880px;
  left: 210px;
  border: none;
  width: 200px;
  height: 200px;
}

.Rutger {
  position: relative;
  border-radius: 50%;
  bottom: 1370px;
  left: 35px;
  border: none;
  width: 200px;
  height: 200px;
}

.Jonathan {
  position: relative;
  border-radius: 50%;
  bottom: 1368px;
  left: 30px;
  border: none;
  width: 200px;
  height: 200px;
  object-fit: fill;
  padding: -50px;
  background-color: #e1ecf9;
}

.Dan {
  position: relative;
  border-radius: 50%;
  bottom: 1360px;
  left: 500px;
  border: none;
  width: 10rem;
  height: 10rem;
}

.John {
  position: relative;
  border-radius: 50%;
  bottom: 1360px;
  left: 530px;
  border: none;
  width: 8rem;
  height: 8rem;
}

.Anouk {
  position: relative;
  border-radius: 50%;
  bottom: 1655px;
  left: 205px;
  border: none;
  width: 178px;
  height: 178px;
}

.card-team {
  min-width: 320px;
}

.card-body {
  border: 1px solid #eaecf0;
  background: #fff;
}

.cardTitle {
  color: #282828;

  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.6px;
  /* 140.952% */
}

.cardText {
  color: #3e3e59;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
