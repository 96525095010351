.newsHeader {
  padding: 130px 0 150px;
  margin-bottom: -100px;
}

.newsCard {
  background-color: #F7F7F7;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px 50px 25px 25px;
  border: 0;
  width: 330px;
}

.newsCardImg {
  background-color: #00000029;
  border-radius: 25px 25px 0 0;
  height: 220px;
}

.newsCardBody {
  padding-bottom: 0;
}

.newsCardFooter {
  background-color: transparent;
  border: 0;
}