.articleContainer {
    padding: 40px 30px 50px;
    margin-top: -100px;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
  }

.helpArticleHeader {
    padding: 130px 0 160px;
}

.tipContainer {
    background-color: #2F8BF7;
    border-radius: 30px;
    color: white;
    padding: 10px 20px;
}

@media only screen and (max-width: 768px) {
    .articleImg {
      width: 100%;
      height: auto;
    }  
}