.pageWrapper {
  background: #f7f7f7;
  padding-top: 130px;
  padding-bottom: 20px;
}

.titleBorder {
  padding-bottom: 15px;
  border-bottom: 1px solid #3081aa;
}

/* ---- table of contents: */
.tableContents {
  margin: 10px 0;
}

.tableContent {
  background: #f7f7f7;
}

.titlePolicy{
  color: #212529;
  font-weight: bold;
  font-size: 22px;
}
.subtitlePolicy{
  margin-top: 15px;
  margin-left: 2%;
  color: #212529;
  font-weight: 500;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .pageContents {
    margin: 30px 10px;
  }
}
