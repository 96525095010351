.fullHome {
  width: 100%;
  opacity: 1;
  overflow: hidden;
}

.align-middle-submit {
  width: 50%;
}

.See-all {
  color: var(--Blue, var(--Blue, #048dc1));
  font-size: 21px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.img-mobile {
  width: 330px;
}

.divContent {
  display: flex;
  justify-content: center;
  padding: 38px 28px;
  min-height: 529px;
  overflow: hidden;
  background-size: cover;
  background-image: linear-gradient(180deg,
      #031a3a 12.97%,
      rgba(3, 26, 58, 0.5) 19.06%,
      #22b7bb 100%),
    linear-gradient(0deg, #031a3a, #031a3a);
}

.containerContent {
  margin-top: 25%;
  margin-bottom: 25%;
}

.img-star-left {
  height: 40px;
  position: absolute;
  bottom: 15%;
  left: -88%;
}

.img-star-right {
  height: 25px;
  position: absolute;
  bottom: 10%;
  right: 0px;
}

.img-star-right_1 {
  height: 17px;
  position: absolute;
  bottom: 45%;
}

.img-star-right_2 {
  height: 24px;
  /* position: absolute;
  bottom     : 45%; */
}

.img-star-right_3 {
  height: 24px;
  float: right;
  /* padding-top: 5%; */
}

.mba {
  position: absolute;
  /* left : 45%; */
  bottom: -150%;
}

.image-cloud {
  margin-left: -50%;
  height: 230px;
  width: 230px;
}

.image-cloud-sm {
  height: 90px;
  width: 90px;
}

.image-cloud-bm {
  height: 160px;
  width: 160px;
  position: absolute;
  /* width: 50%; */
  bottom: 10px;
}

.jounaler-btn {
  margin-top: 28px;
}

.jounaler-btn-learnmore {
  text-align: center;
  display: flex;
  justify-content: center;
}

.jounaler-btn-freetrial {
  margin-top: 5%;
  text-align: center;
  margin-bottom: 5%;
}

.jounaler-sub {
  margin-top: 15px;
  margin-left: 10%;
  width: 80%;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}

.containerImg {
  height: 200px;
  display: inline-block;
}

.homeHeaderContainer {
  padding: 114px 40px 0 40px;
}

.container {
  width: 100%;
  /* height: 600px; */
}

.container-jounaler {
  margin-top: 300px;
}

.classspace {
  height: 700px;
}

.div1 {
  margin-top: 100px;
  height: 600px;
  width: 50%;
  float: left;
}

.imgdiv1 {
  margin-top: 20%;
  width: 100%;
  height: 72%;
}

.div2 {
  margin-top: 100px;
  height: 600px;
  width: 50%;
  /* background-color: antiquewhite; */
  float: right;
}

.textdiv2 {
  font-size: 40px;
  font-weight: 700;
  padding-top: 28px;
  color: #fff;
}

.textdiv3 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}

.text2div2 {
  padding: 28px 0px;
  font-size: 21px;
  font-weight: 400;
  color: #ffffff;
  width: 420px;
}

@media only screen and (max-width: 767px) {
  .text2div2 {
    text-align: center !important;
    width: 320px !important;
    font-size: 18px !important;
  }

  .containerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10%;
  }

  .imgdiv1 {
    margin-top: 0;
    width: 100%;
    height: auto;
  }

  .simplify-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .simplify-header {
    width: 345px !important;
    font-size: 28px !important;
  }

  .supported-currencies {
    gap: 0px !important;
  }
}

.imgdivXeroicons {
  padding-top: 5%;
}

.homeHeaderTitle {
  color: #ffffff;
  font-weight: 730;
  font-size: 36px;
}

.homeHeaderSub {
  color: #ffffff;
  /* font-weight: 730; */
  font-size: 26px;
}

.aemIconDiv {
  display: flex;
  justify-content: center;
}

.aemLogo {
  padding-top: 110px;
}

.diagramImg {
  width: 100%;
  height: auto;
}

.vedioWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.aemWalletImage {
  width: 100%;
  /* TODO */
}

.testAEM {
  font-size: 2.5rem;
  font-weight: 500;
}

.testAEM::first-letter {
  color: #2f8bf7;
}

.textLernmore {
  font-size: 20px;
  font-weight: bold;
  color: #2f8bf7;
  opacity: 1;
}

.aemIntro {
  text-align: center;
  color: #094674;
  opacity: 1;
  font-size: 20px;
}

.subtitleJounaler {
  font-size: 38px;
  font-weight: bold;
}

.contain-wallet {}

.title_export {
  color: #22b7bb;
  font-weight: bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title_export_pick {
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitle_export {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.import_export_title {
  color: #254674;
  font-weight: bold;
  font-size: 35px;
}

.import_export_subtitle {
  padding-top: 5%;
  color: #0b4875;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title-crypto {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 40px;
}

.title-crypto-sub {
  padding-top: 5%;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
}

.container-crypto {
  background-color: #094674;
}

.container-ournetwork {
  background: #ededed;
}

.iconcrpto {
  padding-top: 2%;
}

.jounaler-btn-crypto {
  margin-top: 5%;
  text-align: center;
}

.signup-center {
  background: #41a1c8;
  /* padding-bottom: 5%; */
}

.title-signup {
  color: #ffffff;
  font-size: 49px;
}

.sub-signup {
  color: #ffffff;
  font-size: 24px;
}

.spaceHeight {
  height: 100px;
}

.divimage {
  height: 200px;
}

.streamline {
  background: #348db5;
}

.imgresponsive {
  width: fit-content;
  height: fit-content;
}

.text-connected {
  font-weight: bold;
  color: #254674;
  font-size: 30px;
}

.mobile {
  display: none;
}

.title-ournetwork {
  font-size: 40px;
  font-weight: bold;
}

.logoCryto {
  width: 80px;
  padding-bottom: 80px;
}

.logoCryto_end {
  width: 80px;
  padding-bottom: 20px;
}

.simplify-header {
  width: 480px;
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.btnSimplify {
  width: 283px !important;
  height: 53px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(87deg, #0a4777 -78.1%, #22b7bb 102.37%);
  border: 2px solid transparent;
  border-radius: 4px;
  color: #0b4875;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.clickbBtnSimplify {
  cursor: pointer;
  width: 283px !important;
  height: 53px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0b4875;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.btnSimplify:hover {
  border-radius: 4px;
  background: #0b4875;
  color: #fff;
  text-align: center;
}

.btnSimplify:focus {
  border-radius: 4px;
  background: #0b4875;
  color: #fff;
  text-align: center;
}

.btnLearnMore {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #41a1c8;
  border: unset;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  /* width: 162px; */
  padding: 0px 30px;
  height: 53px;

  &:hover {
    background-color: #fff;
    color: #41a1c8;
    outline: 1px solid #41a1c8;
  }
}

.connected {
  /* background: rgba(65, 161, 200, 0.1); */
}

.currency20 {
  text-align: center;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(103deg,
      rgba(3, 65, 161, 0.78) -12.23%,
      #22b7bb 99.35%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.currency20home {
  text-align: center;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(103deg,
      rgba(3, 65, 161, 0.78) -12.23%,
      #22b7bb 99.35%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-offer {
  width: 600px;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px;
  color: #55595a;
  text-align: center;
}

.journalerFeature {
  background-color: #031a3a;
  border-radius: 10px;
}

.manage-crypto {
  color: #fff;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.manage-crypto-sub {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.linkStoreMain {
  height: 60px;
}

.btnGroup_learnmore {
  display: flex;
  background-color: #41a1c8;
  border-radius: 4px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width: 278px;
  height: 53px;
  color: #fff;
  border: unset;

  &:hover {
    background-color: #fff;
    color: #41a1c8;
    outline: 1px solid #41a1c8;
  }
}

.journaler-center {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .scroll-content {
    animation: scroll 4s linear infinite;
  }

  .imgresponsiveQb {
    width: 80%;
  }

  .journaler-center {
    font-size: 30px;
  }

  .diagramImg {
    width: 100%;
    height: auto;
  }

  .aemLogo {
    padding-top: 10px;
  }

  .aemSectionBg {
    background: transparent linear-gradient(180deg, #094674 0%, #05233a 100%) 0% 0% no-repeat padding-box;
  }

  .div2 {
    /* width: 100%; */
    width: 80%;
    float: none;
    margin-left: 10%;
  }

  .div1 {
    width: 0;
    height: 0;
  }

  .mba {
    display: none;
  }

  .imgdivXeroicons {
    margin-left: 0px;
  }

  .textdiv2 {
    font-size: 28px;
    text-align: center;
  }

  .btnGroup_greenBgBtn_home {
    padding: 16px 30px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 272px;
    height: 53px;
    font-weight: 700;
    color: #0b4875;

    &:hover {
      color: #fff;
      background-color: #0b4875;
      outline: 1px solid #0b4875;
    }
  }

  .btnGroup_learnmore {
    padding: 15px 10px;
    background-color: #41a1c8;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 50px;
    text-align: center;
    margin-left: 50px;
    font-size: 12px;
  }

  .btnGroup_whiteBorderBtn {
    padding: 15px 10px;
    border-radius: 30px;
    border: 2px solid #ffffff;
    font-weight: bold;
    color: white;
    font-size: 12px;
    width: 40%;
  }

  .aemIntro {
    text-align: center;
    color: #094674;
    opacity: 1;
    font-size: 14px;
  }

  .img-fluid {
    width: 100%;
  }

  .subtitleJounaler {
    font-size: 20px;
    font-weight: bold;
  }

  .title_export {
    font-size: 18px;
  }

  .subtitle_export {
    font-size: 14px;
    height: 90%;
  }

  .import_export_title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .import_export_subtitle {
    font-size: 10px;
  }

  .spaceHeight {
    height: 50px;
  }

  .jounaler-sub {
    font-size: 14px;
    width: 75%;
  }

  .title-crypto {
    font-size: 20px;
  }

  .title-crypto-sub {
    font-size: 14px;
  }

  .title-signup {
    font-size: 30px;
    font-weight: 700;
  }

  .sub-signup {
    font-size: 14px;
  }

  .containerImg {
    margin-left: 15%;
  }

  .jounaler-btn {
    text-align: center;
  }

  .imgdivhome {}

  .contain-wallet {
    top: 0%;
  }

  .jounaler-btn-freetrial {
    margin-top: 15%;
  }

  .title-ournetwork {
    font-size: 30px;
    font-weight: bold;
  }

  .logoCrytoMobile {
    width: 80px;
  }

  .manage-crypto {
    padding-top: 15px;
    font-size: 18px;
    text-align: center;
  }

  .manage-crypto-sub {
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
  }

  .linkStoreMain {
    height: 50px;
  }

  .text-offer {
    font-size: 14px;
  }

  .hiddenMobileView {
    display: none;
  }

  .currency20home {
    font-size: 30px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .imgdiv1 {
    height: 85%;
  }

  .div1 {
    width: 0;
  }

  .div2 {
    width: 77%;
  }

  .imgdivXeroicons {}

  .containerContent {
    margin-top: 10%;
    margin-bottom: 0%;
  }

  .diagramImg {
    width: 100%;
    height: auto;
  }

  .aemLogo {
    padding-top: 10px;
  }

  .aemSectionBg {
    background: transparent linear-gradient(180deg, #094674 0%, #05233a 100%) 0% 0% no-repeat padding-box;
  }

  .mba {
    display: none;
  }
}

@media only screen and (min-width: 860px) and (max-width: 1400px) {
  .mba {
    top: 250%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1800px) {
  .mba {
    top: 210%;
  }
}

/* ------- find out more section */
.findOutMoreContainer {
  background: #1f6c97;
  padding: 20px;
}

.navtext {
  font-size: 16px;
  color: white;
}

.seeOurProductSection {
  background-color: #222222;
  color: white;
  padding: 100px 0;
  text-align: center;
}

.scroller {
  max-width: 100%;
  mix-blend-mode: darken;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 20s) var(--_animation-direction, forwards) linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

.scroller {
  --_animation-duration: 15s;
  --_animation-direction: forwards;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.simplify-btn-group {
  display: flex;
  justify-content: center;
  gap: 26px;
}

.supported-currencies {
  gap: 36px;
}