.cardContainerPricing {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    padding-bottom: 33px;
}

.subscriptionCards {
    display: flex;
    justify-content: space-around;
    width: 229px;
    padding: 22px;
    border-radius: 15px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
    margin-right: 10px
}

.cardImage {
    margin-right: 14px;
}

.card {
    width: 229px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscriptionCards:hover {
    background: var(--Light-Blue, #41A1C8);
    border-radius: 15px;
    box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
}

.subscriptionCards:hover .card {
    background: var(--Light-Blue, #41A1C8);

}

.subscriptionCards:hover .cardImage {
    color: #41A1C8;
    content: url('../../../../assets/pricing/CheckCircle.png');

}

.subscriptionCards:hover .cardDuration {
    color: var(--Medium-grey, rgba(222, 222, 222, 0.80));
}

.subscriptionCards:hover .cardQuote {
    color: #fff;
}

.subscriptionCards:hover .cardType {
    color: #fff;
}

.subscriptionCards:hover .cardPrice {
    color: #fff;
}

.subscriptionCards:hover .cardDetailValue {
    color: #fff;
}

.subscriptionCards:hover .cardButton {
    color: #0a4777;
    background-color: #fff;
    border: 1px solid #0a4777;
}


.cardType {
    color: var(--Navy, #0a4777);
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 15px;
}

.cardPriceRow {
    display: flex;
    align-items: baseline;
    padding-bottom: 15px;
}

.cardPrice {
    color: var(--neutral-800, #170f49);
    font-size: 48px;
    font-weight: 700;
}

.cardDuration {
    color: var(--neutral-600, #6f6c90);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
}

.cardDetail {
    display: flex;
    align-items: flex-start;
    padding-bottom: 16px;
    word-wrap: break-word;
}

.cardDetailValue {
    color: var(--Black, #202020);
    font-size: 14px;
    font-weight: 400;
}

.cardQuote {
    padding-bottom: 23px;
    color: var(--Black, #202020);
    font-size: 14px;
    font-weight: 400;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.cardButton {
    height: 50px;
    width: 180px;
    border-radius: 4px;
    background: var(--Navy, #0a4777);

    display: flex;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 11px;
    align-self: stretch;

    color: var(--White, #fff);
    font-size: 14px;
    font-weight: 500;

    position: relative;
    bottom: 0;
}



@media only screen and (max-width: 500px) {
    .cardContainerPricing {
        padding-left: 10px;
        padding-right: 10px;

    }

    .subscriptionCards {
        width: 200px;
        height: fit-content;
        margin-bottom: 15px;
    }

}

.card-buttons-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}