.notFoundContainer {
  padding: 124px 0 90px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.logoImgDiv {
  width: fit-content;
}
