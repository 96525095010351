.soultionHeader {
  padding: 114px 40px 0;
}

.colLeft {
  padding-left: 140px;
}

.conHeader {
  padding-top: 10%;
  height     : auto;
}

.headerUsecase {
  color     : #FFFFFF;
  font-size : 24px;
  text-align: center;
}

.titleUsecase {
  color      : black;
  font-size  : 40px;
  font-style : normal;
  font-weight: 700;

  text-align: center;
  width     : 80%;

}

.featureCon {
  background             : #DFE7EB;
  backdrop-filter        : blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.contentUsecase {
  color     : var(--Grey, #9E9E9E);
  text-align: center;
  width     : 90%;

  font-size  : 24px;
  font-style : normal;
  font-weight: 500;
  line-height: 33.1px;
  /* 137.917% */

}

.titleFinancial {
  color     : var(--Black, #202020);
  text-align: center;

  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  line-height: 56.9px;
}

.bgrow {
  background   : #FFFFFF;
  height       : 100%;
  border-radius: 10px;
}

.bgcolor-light {
  background-color: #FFFFFF;
}

.bgcolor-dark {
  background-color: #DFE7EB;
}

.imgtitleReport {
  padding-top : 10%;
  padding-left: 5%;
}

.containSimplify {
  background     : lightblue no-repeat center;
  background-size: cover;
  align-items    : center;
  display        : flex;
  justify-content: center;
  height         : 400px;
  width          : 400px;
}

.simplifyUseCase {
  background: #202020;
}

.titleSimplify {
  color      : #FFF;
  text-align : center;
  font-size  : 49px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.titleSimplifyGradient {
  background             : linear-gradient(103deg, rgba(3, 65, 161, 0.78) -12.23%, #22B7BB 99.35%);
  background-clip        : text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btnSimplify {
  text-align: center;
}

.titleReport {
  color      : var(--Black, #202020);
  font-size  : 28px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.subReport {
  color      : var(--Black, #202020);
  font-size  : 21px;
  font-style : normal;
  font-weight: 500;
  line-height: 29.6px;
}

.imgsubReport {
  /* padding-top: 5%; */
  height      : auto;
  padding-left: 5%;
}

.btnAccount {}

.ImageXero {
  position: absolute;
  left    : 45%;
  bottom  : -14%;
}

.ImageBussiness {
  width   : 100%;
  position: absolute;
  bottom  : 10%;
}

.imgBussiness {
  width: 100%;
}

.subTitleBu {
  margin-top: 120px;
}

.imageContainer {
  position: sticky;
}

.walletConnectionSectionContainer {
  padding: 120px 10px 50px;
}

.financialReportingSectionContainer {}

.signupSectionContainer {
  text-align         : center;
  padding            : 120px 10px;
  color              : white;
  /* background-image: url(../../../assets/journaler/solutions/accountImage.png); */
  background         : #094674 0% 0% no-repeat padding-box;
  background-position: center;
  background-size    : cover;
}

.signupSectionContainerAccount {
  text-align         : center;
  padding            : 120px 10px;
  color              : white;
  background-image   : url(../../../assets/journaler/solutions/accountImage.png);
  /* background      : #094674 0% 0% no-repeat padding-box; */
  background-position: center;
  background-size    : cover;
}


.lightgreyText {
  color: #F7F7F7;
}

.freeTrailBtn {
  background-color: white;
  color           : #2B648D;
  padding         : 15px 10px;
  margin          : 30px 0;
  border-radius   : 30px;
  font-weight     : bold;
  width           : 300px;
  font-size       : 15px;
  background      : #FFFFFF 0% 0% no-repeat padding-box;
}

.featureSectionHeader {
  padding            : 80px 10px 20px;
  background-image   : url(../../../assets/journaler/solutions/bg2.svg);
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : cover;
}

.featureSectionTwoHeader {
  padding            : 80px 10px 20px;
  background-image   : url(../../../assets/journaler/solutions/bg3.svg);
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : cover;
}

.titleContent {
  text-align: center;
  color     : white;
  font-size : 24px;

}

.mobileRes {
  visibility: hidden;
  width     : 0;
  height    : 0;
}

.titleSection {
  /* color: #05233A */
  font-size  : 36px;
  font-weight: bold;
  color      : #FFFFFF;
}

.subSection {
  color    : #FFFFFF;
  font-size: 20px;
}

.titleSectionMid {
  color      : var(--Black, #202020);
  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  line-height: 56.9px;
  width      : 90%;
}

.titleSectionMidGradient {
  background             : linear-gradient(100deg, rgba(3, 65, 161, 0.78) 29.56%, #22B7BB 57.1%);
  background-clip        : text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitletitleSectionMid {
  color      : var(--Grey, #9E9E9E);
  width      : 80%;
  font-size  : 24px;
  font-style : normal;
  font-weight: 500;
  line-height: 33.1px;
  /* 137.917% */
}

.titleMidHeader {
  color: var(--Black, #202020);

  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  /* 142.25% */
}

.titleMidHeaderGradient {
  background             : linear-gradient(99deg, rgba(3, 65, 161, 0.78) -13.02%, #22B7BB 50.48%);
  background-clip        : text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-sub {
  color       : var(--Grey, #9E9E9E);
  font-size   : 21px;
  font-style  : normal;
  font-weight : 500;
  line-height : 29.6px;
  padding-left: 0px;
}

.img-journaler {
  width     : 100%;
  max-width : 350px;
  max-height: 350px;
}

@media only screen and (max-width: 1700px) {
  .ImageXero {
    left  : 40%;
    bottom: -40%;
  }

  .ImageBussiness {
    width   : 100%;
    position: absolute;
    bottom  : -20%;
  }
}

@media only screen and (max-width: 1400px) {
  .ImageXero {
    left  : 40%;
    bottom: -70%;
  }

  .ImageBussiness {
    width   : 100%;
    position: absolute;
    bottom  : -35%;
  }
}

@media only screen and (max-width: 860px) {
  .img {
    max-width: 100%;
  }

  .mobileRes {
    visibility: visible;
    width     : 100%;
    height    : 500px;
  }

  .desktopRes {
    visibility: hidden;
    width     : 0;
    height    : 0;
  }

  .homeHeaderTitle {
    text-align: center;
  }

  .homeHeaderSub {
    text-align: center;
  }

  .freeTrailBtn {
    font-size: 18px;
  }

  .soultionHeader {
    height                 : 1000px;
    /* background          : transparent linear-gradient(180deg, #074673 0%, #2677A0 19%, #368FB7 30%, #5EA6C6C1 41%, #83BBD488 49%, #AAD2E34C 57%, #FFFFFF32 77%, #FFFFFF17 100%) 0% 0% no-repeat padding-box; */
    background             : linear-gradient(90deg, rgba(7, 70, 115, 1) 0%, rgba(38, 119, 160, 1) 35%, rgba(54, 143, 183, 1) 100%);
    /* background          : url(images/bg.jpg) no-repeat center center fixed;  */
    -webkit-background-size: cover;
    -moz-background-size   : cover;
    -o-background-size     : cover;
    background-size        : cover;

    opacity: 1;
  }

  .featureSectionHeader {
    background: linear-gradient(90deg, rgba(7, 70, 115, 1) 0%, rgba(38, 119, 160, 1) 35%, rgba(54, 143, 183, 1) 100%);
  }

  .featureSectionTwoHeader {
    background: linear-gradient(90deg, rgba(7, 70, 115, 1) 0%, rgba(38, 119, 160, 1) 35%, rgba(54, 143, 183, 1) 100%);
  }

  .titleSection {
    color      : #FFFFFF;
    font-size  : 31px;
    font-weight: bold;
  }

  .subSection {
    color    : #FFFFFF;
    font-size: 21px;
  }

  .subTitleBu {
    margin-top: 20px;
  }

  .ImageXero {
    left  : 40%;
    bottom: 10%;
  }

  .ImageBussiness {
    bottom: 30%;
  }
}

@media only screen and (max-width: 600px) {
  .titleContent {
    font-size: 24px;
  }

  .homeHeaderTitle {
    font-size: 27px;
  }

  .homeHeaderSub {
    font-size: 14px;
  }

  .subSection {
    font-size: 14px;
  }

  .ImageXero {
    left  : 40%;
    bottom: -15%;
  }

  .imageContainer {
    width: 120px;
  }

  .titleUsecase {
    margin-top   : 15px;
    margin-bottom: 15px;
    font-size    : 30px;
    line-height  : auto;
  }

  .conHeader {
    padding-top: 25%;
  }

  .title {
    font-size: 16px;
  }

  .titleSimplify {
    font-size: 30px;
  }

  .walletConnectionSectionContainer {
    padding-top: 40px;
  }

  .imgres {
    width: 30%;
  }

  .titleSectionMid {
    width      : 80%;
    font-size  : 30px;
    text-align : center;
    margin-left: 10%;
    line-height: 40px;
  }

  .contentUsecase {
    font-size: 14px;
  }

  .subtitletitleSectionMid {
    font-size   : 14px;
    width       : 90%;
    padding-left: 10%;
  }

  .titleMidHeader {
    font-size: 20px;
  }

  .content-sub {
    font-size: 14px;
  }

  .currency20 {
    font-size: 20px;
  }

  .titleFinancial {
    font-size: 30px;
  }

  .titleReport {
    font-size: 20px;
  }

  .subReport {
    font-size: 14px;
  }

  .header-report {
    width       : 90%;
    padding-left: 10%;
  }

  .image-wallet {
    border-radius: 10px;
  }
}