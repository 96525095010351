.contactUsHeadernew {
  /* padding: 130px 40px 110px; */
  background: transparent linear-gradient(180deg, #074673 0%, #2677A0 19%, #368FB7 30%, #5EA6C6C1 41%, #83BBD488 49%, #AAD2E34C 57%, #FFFFFF32 77%, #FFFFFF17 100%) 0% 0% no-repeat padding-box;  
  min-height: 800px;
}
.titleContact{
  color: white;
  font-weight: bold;
  padding-top: 120px;
}

.subContact{
  color: white;
  font-size: 26px;
}

.titleDetail{
  font-size: 22px;
  color: #000000;
  font-weight: bold;
}
.step{
  margin-left: 5%;
  font-size: 22px;
  color: #707070;
}
.containerHeader{
  width: 100%;
  height: 300px;
}
.getInTouchButton {
  margin: 20px 0px;
  background-color: #ffffff;
  border-radius: 30px;
  font-weight: bold;
  width: 200px;
}

.contactFormBg {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  /* height: 450px; */
  /* margin-top: -80px;
  margin-bottom: 80px; */
  width: 70%;
}

.contactUsForm {
  width: 85%;
}

.contactFormInput {
  height: 45px;
  border: 1px solid #dddddd;
  width: 300px;
}

.lightGreyBorder {
  border: 1px solid #dddddd;
}

.formLabel {
  font-size:14px;
  color: #707070;
}

.sendBtn {
  background-color: #0F4B73;
  border-radius: 30px;
  font-weight: bold;
  border: 0;
  width: 200px;
  height: 40px;
  margin-top: 20px;
  height: 53px;
  border-radius: 4px;
  color: white;
}

.sendBtn:hover {
  background-color: #2b648d;
}

.joinNewsBg {
  text-align: center;
  background-color:  var(--Light-Blue, #41A1C8);
  color: white;
}

.notification {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 5px 10px;
}


@media only screen and (max-width: 860px) {
  .titleContact{
    text-align: center;
    padding-top: 100px;
    font-size: 70px;
  }
  .subContact{

  }

  .contactFormBg{
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .contactFormBg {
    width: 100%;
  }  
  .titleContact{
    padding-top: 50px;
    font-size: 35px;
  }
  .subContact{
    font-size: 12px;
    text-align: center;
  }
  .contactFormInput{
    width: 100%;
  }
  .contactUsHeadernew{
    height: fit-content;
  }
  .containerHeader{
    height: fit-content;
    padding-bottom: 35px;
  }
.contactFormBg{
  margin-bottom: 30px;
}
}
