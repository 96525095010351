.usecase-header {
    color      : var(--Black, #202020);
    font-size  : 40px;
    font-style : normal;
    font-weight: 700;
    line-height: 56.9px;
    text-align : center;
}

.clickbBtnSimplifyUsecase {
    min-width      : 250px;
    padding        : 16px 30px;
    justify-content: center;
    align-items    : center;
    background     : #0B4875;
    border         : 2px solid transparent;
    border-radius  : 4px;
    color          : #FFF;
    text-align     : center;
    font-size      : 18px;
    font-weight    : 700;
    line-height    : normal;
}

.btnSimplifyUsecase {
    min-width: 250px;

    padding        : 16px 30px;
    justify-content: center;
    align-items    : center;
    background     : linear-gradient(#fff, #fff) padding-box,
        linear-gradient(87deg, #0A4777 -78.1%, #22B7BB 102.37%);
    border       : 2px solid transparent;
    border-radius: 4px;

    color      : #0B4875;
    text-align : center;
    font-size  : 18px;
    font-weight: 700;
    line-height: normal;

}


.btnSimplifyUsecase:hover {
    border-radius: 4px;
    background   : #0B4875;
    color        : #FFF;
    text-align   : center;
}

.btnSimplifyUsecase:focus {
    border-radius: 4px;
    background   : #0B4875;
    color        : #FFF;
    text-align   : center;
}

@media only screen and (max-width: 600px) {
    .clickbBtnSimplify {
        height   : 50px;
        /* width : 110px; */
    }

    .btnSimplify {
        height   : 50px;
        /* width : 110px; */
    }
}