.aemplusMainHeader {
  padding: 114px 40px 0 40px;
  margin-bottom: -100px;
  background-color: #9DB6D3;
  overflow: hidden;
}

.aemWhiteLogo {
  margin: 50px 0 20px 0;
}
.titlePlus{
  font-weight: bold;
  font-size: 33px;
  color: #ffffff;
}

.liveNow{
  margin-top: -500px;
  background: url('../../../assets/aemPlus/Intersection.png') 0% 0% no-repeat padding-box;
  width: 100%;
  /* background-size: cover; */
  height: 900px;
}

.subPlus{
  font-size: 30px;
  color: #ffffff;
}
.downloadBtn {
  padding: 10px;
  background-color: white;
  color: black;
  border-radius: 40px;
  width: 256px;
}
.imageAemplus{
  /* background: transparent url('../../../assets/aemPlus/img_transaction_new.png') 0% 0% no-repeat padding-box; */
  /* border-radius: 70px; */
  opacity: 1;
  background: transparent;
}
.startAemplusBtn {
  padding: 15px 10px;
  background-color: #ffffff;
  border-radius: 30px;
  font-weight: bold;
  width: 256px;
}

.sectionTwoBg {
  padding: 130px 0 80px 0;
  /* background-color: #222222; */
  color: white;
}

.SubSendAndRecievetitle{
  color: #000000;
  font-size: 40px;
  font-weight: bold;
}

.SendAndRecievetitle{
  color: #707070;
  /* font-size: 20px; */
}
.featureContent {
  padding-left: 50px;
}

.aemDemoBg {
  background: url('../../../assets/aemPlus/Intersection.png') 0% 100% no-repeat padding-box;
  background-size: cover;
}
.seamlessTransactionImg{
  margin-top: 100px;
}

.titleNew{
  margin-top: 100px;
  color: #000000;
  font-size: 39px;
  font-weight: bold;
}

.mobileMain{
  width: 0;
  height: 0;
  visibility: hidden;
}
/* @media only screen and (max-width: 768px) {
} */
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .seamlessTransactionImg {
    width: 70%;
    padding-top: 70px;
    margin-top: 100px;
  }
  .featureImg {
    width: 95%;
    text-align: center;
    padding-bottom: 30px;
  }
  .featureContent {
    padding: 0 20px;
  }  

  .desktopMain{
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .mobileMain{
    visibility:visible;
    height: 500px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .featureContent {
    padding-left: 0;
  }
  .aemplusMainHeader {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
  .featureImg {
    width:100%;
    text-align: center;
    padding-bottom: 30px;
  }
  .seamlessTransactionImg {
    width: 75%;
    padding-top: 30px;
    margin-top: 100px;

  }
  .featureContent {
    padding: 0 20px;
  }  
}

@media only screen and (max-width: 600px) {
  .aemMockImg {
    width: 90%;
  }
  .desktopMain{
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .mobileMain{
    visibility:visible;
    height: 500px;
    width: 100%;
  }
  .SendAndRecievetitle{
    text-align: center;
    /* font-size: 14px; */
  }
  .SubSendAndRecievetitle {
    text-align: center;
    /* font-size: 14px; */
  }
  .titlePlus{
    font-size: 16px;
  }
  .subPlus{
    font-size: 16px;
  }

}