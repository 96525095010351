.xeroHeaderContainer {
  padding-top: 124px;
  padding-bottom: 200px;
}

.h1BoldJounaler {
  font-weight: bold;
  color: white;
}

.desIntegrade{
  color: #FFFFFF;
  font-size: 20px;
}

.colaboutXero{
  text-align: left;
}
.lijounalerHelp{
  text-align: left !important;
}
@media only screen and (max-width: 600px) {
  .h1BoldJounaler{
    font-size: 25px;
    color: #FFFFFF;
  }

  .jounalerHelpData{
    font-size: 15px !important;
  }
  .subTitle{
    font-size: 15px !important;
  }
  .detailHelpData{
    font-size: 18px !important;
    text-align: left;
  }
  .lijounalerHelp{
    font-size: 15px !important;
  }
  .titlehowIntegration{
    font-size: 25px !important;
  }
  .titlestepsData{
    font-size: 25px !important;
    margin-left: 15px;

  }
  .colaboutXero{
    font-size: 14px;
  }
}