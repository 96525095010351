/* .tableContainer {
    border: 1px solid #dee2e6
} */


.tableContainer {
    /* Important */
    position  : sticky;
    z-index   : 100;
    top       : 64px;
    background: white;
}


.title-plan {
    color     : var(--neutral-800, #170F49);
    text-align: center;

    font-size  : 50px;
    font-style : normal;
    font-weight: 700;
    line-height: 62px;
    /* 124% */
}

.th-header {
    display: contents;
}

.planName {
    font-size  : 24px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
    color      : var(--Navy, #0A4777);
}

.plan-pricing {
    color      : var(--Black, #202020);
    font-size  : 38px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
}

.planNamect {
    font-size: 30px;
}

.plan-duration {
    color      : var(--neutral-600, #6F6C90);
    font-size  : 14px;
    font-style : normal;
    font-weight: 400;
    line-height: 21px;
    /* 150% */
}

.btn-start-now {
    min-width      : 150px;
    padding        : 12px 10px;
    justify-content: center;
    align-items    : center;
    border-radius  : 4px;
    background     : #41A1C8;
    border         : unset;

    color: #FFF;

    text-align : center;
    font-size  : 14px;
    font-style : normal;
    font-weight: 500;
    line-height: normal;
    border     : 1px solid #41A1C8;
}

.btn-start-now:hover {
    background: #fff;
    color     : #41A1C8;
    border    : 1px solid #41A1C8;

}


.paddingBtn {
    padding-left   : 5px;
    display        : flex;
    justify-content: center;
}

.th-detail {
    padding    : 22px 22px 22px 60px;
    align-items: center;
    gap        : 12px;
    flex-shrink: 0;
    align-self : stretch;
}

.text-detail {
    padding: 22px;
    color  : var(--Navy, #0A4777);

    font-size  : 18px;
    font-style : normal;
    font-weight: 400;
    line-height: 16px;
}

.text-detail-ent {
    padding: 22px;
    color  : black;

    font-size  : 18px;
    font-style : normal;
    font-weight: 400;
    line-height: 16px;
}

.check-plan {
    padding: 22px;
}

.check-plan-ent {
    padding: 22px;
    color  : black;
}

@media only screen and (max-width: 500px) {
    .title-plan {
        font-size: 30px;
    }

    .planName {
        font-size: 16px;
    }

    .plan-pricing {
        font-size: 26px;
    }

    .planNamect {
        font-size: 16px;
    }

    .tableContainer {
        position: relative;
        top     : 0px;
    }

    .mobile-table {
        overflow-x: auto;
    }
}