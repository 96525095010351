.btn-select {
    width        : 200px;
    padding      : 16px;
    border-radius: 4px;
    border       : 1px solid var(--Medium-grey, #E5E5E5);

    font-size: 16px;
}

.btn-inside {
    width: 130px;
}

.titleSelect {
    color        : #979797;
    padding-right: 70px;
}


@media only screen and (max-width: 500px) {
    .select-sim {
        width: 100px
    }

    .btn-select {
        width      : 60%;
        margin-left: 20%;
    }
}