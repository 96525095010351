body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 18px;
}

h6 {
  font-size: 21px
}

h5 {
  font-size: 24px
}

h4 {
  font-size: 28px
}

h3 {
  font-size: 32px
}

h2 {
  font-size: 40px
}

h1 {
  font-size: 49px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* <a>tag text color */
a {
  color: #048dc1;
}

/* global header background color */
.globalHeaderBg {
  background-image: linear-gradient(to right, #048dc1, #094674);
  color           : #ffffff;
}

.globalHeaderBgL {
  height  : 1000px;
  overflow: hidden;
  background: transparent linear-gradient(180deg,
      #074673 19%,
      #2677a0 47%,
      #368fb7 57%,
      #5ea6c6c1 77%,
      #83bbd488 100%) 0% 0% no-repeat padding-box;
}

.globalHeaderBgLBesiness {
  height                    : 1000px;
  border-bottom-left-radius : 50% 25%;
  border-bottom-right-radius: 50% 25%;
  background                : transparent linear-gradient(180deg,
      #074673 19%,
      #2677a0 47%,
      #368fb7 57%,
      #5ea6c6c1 77%,
      #83bbd488 100%) 0% 0% no-repeat padding-box;
}

.globalHeaderPlus {
  height       : 1000px;
  /* background: transparent linear-gradient(180deg, #074673 0%, #2677A0 19%, #368FB7 30%, #5EA6C6C1 41%, #83BBD488 49%, #AAD2E34C 57%, #FFFFFF32 77%, #FFFFFF17 100%) 0% 0% no-repeat padding-box; */
}

.globalHeaderBgS {
  /* background: transparent linear-gradient( #ffffff 20%, #2F8BF7); */
  /* color: #094674; */
}

.globalHeaderBgSNetwork {
  background: transparent linear-gradient(180deg,
      #074673 0%,
      #2677a0 19%,
      #368fb7 30%,
      #5ea6c6c1 41%,
      #83bbd488 49%,
      #aad2e34c 57%,
      #ffffff32 77%,
      #ffffff17 100%) 0% 0% no-repeat padding-box;
}

.containerTeam {
  background: linear-gradient(#2b83b7, #ffffff 90%);
}

/* global header title style */
.globalHeaderTitle {
  font-size  : 40px;
  font-weight: 900;
}

/* button group style --- in blue bg */
.whiteBgBtn {
  padding         : 15px 10px;
  background-color: #ffffff;
  border-radius   : 30px;
  font-weight     : bold;
  margin-right    : 50px;
  width           : 256px;
}

.whiteBgBtnText {
  color: #2b648d;
}

.whiteBgBtnText:hover {
  color: #2b648d;
}

.noBgBtn {
  border-radius: 30px;
  border       : 2px solid #ffffff;
  font-weight  : bold;
  width        : 256px;
}

.noBgBtnText {
  color          : #ffffff;
  text-decoration: none;
}

.noBgBtnText:hover {
  color          : #ffffff;
  text-decoration: none;
}

/* button group style one - in blue bg*/
/* button one, white bg with blue text  */
.btnGroup_whiteBgBtn {
  padding         : 15px 10px;
  background-color: #ffffff;
  border-radius   : 30px;
  font-weight     : bold;
  margin-right    : 50px;
  width           : 256px;
}

.btnGroup_greenBgBtn {
  padding         : 15px 10px;
  background-color: #41A1C8;
  border-radius   : 30px;
  font-weight     : bold;
  margin-right    : 50px;
  width           : 225px;
}

.btnGroup_greenBgBtn_home {
  display         : flex;
  align-items     : center;
  justify-content : center;
  background-color: #fff;
  border-radius   : 4px;
  font-weight     : bold;
  width           : 250px;
  height          : 53px;
  color           : #0B4875;
}

.btnGroup_greenBgBtn_home:hover {
  background-color: rgba(11, 72, 117, 0.80);
  color           : white;
}

.btn_supported_currencies {
  padding         : 15px 10px;
  background-color: #0b4875;
  border-radius   : 4px;
  font-weight     : bold;
  width           : 225px;
}

.btnGroup_greenBgBtn_mailchip {
  margin-top      : 15%;
  margin-bottom   : 15%;
  padding         : 15px 10px;
  background-color: #24abf2;
  border-radius   : 3px;
  font-weight     : bold;
  width           : 225px;
  color           : #ffffff;
}

.btnGroup_greenBgBtn_simplify {

  padding         : 15px 10px;
  background-color: #24abf2;
  border-radius   : 3px;
  font-weight     : bold;
  width           : 225px;
  color           : #ffffff;
}

.btnGroup_greenBgBtn_usecase {
  padding      : 16px 30px;
  font-size    : 18px;
  font-style   : normal;
  font-weight  : 700;
  line-height  : normal;
  border-radius: 4px;
  background   : #0B4875;
}

.btnGroup_mailBgBtn {
  background-color: #568cf7;
  border-radius   : 30px;
  margin-top      : 30px;
  width           : 200px;
}

/* button two, white border with white text  */
.btnGroup_whiteBorderBtn {
  padding      : 15px 10px;
  border-radius: 30px;
  border       : 2px solid #ffffff;
  font-weight  : bold;
  width        : 225px;
  color        : white;
}

.btnGroup_whiteBorderBtn:hover {
  color: white;
}

@media screen and (max-width: 600px) {
  .btnGroup_greenBgBtn_mailchip {
    width: 170px;
  }

  .btnGroup_greenBgBtn_usecase {
    font-size: 12px;
  }

  .btn_supported_currencies {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btnGroup_whiteBgBtn {
    margin-right : 0;
    margin-bottom: 20px;
  }
}

/* button group style two - in white bg*/
/* button one, blue bg with whit text  */
.btnGroup_blueBgBtn {
  padding         : 15px 10px;
  background-color: #3081aa;
  border-radius   : 30px;
  font-weight     : bold;
  margin-right    : 50px;
  width           : 256px;
  color           : white;
}

/* button two, blue border with blue text  */
.btnGroup_blueBorderBtn {
  padding      : 15px 10px;
  border-radius: 30px;
  border       : 2px solid #3081aa;
  font-weight  : bold;
  width        : 256px;
  color        : #3081aa;
}

.btnGroup_blueBorderBtn:hover {
  color: #3081aa;
}

@media only screen and (max-width: 767px) {
  .btnGroup_blueBgBtn {
    margin-right : 0;
    margin-bottom: 20px;
  }
}