.helpHeader {
  padding: 130px 40px 0;
}

.h1Bold {
  font-weight: bold;
}
.h1BoldUs {
  font-weight: bold;
  color: white;
}

.searchInput {
  border-radius: 20px;
}

.topicCard {
  border-radius: 15px;
}

.cardContainer {
  padding: 30px 0 138px;
}

.bodyContainer {
  padding: 0 20px;
}

.bodyTitle {
  font-size: 33px;
  font-weight: bold;
}

.articleTopic {
  color: #4683e9;
  font-size: 20px;
}

.listItem {
  background-color: #f7f7f7;
  border-radius: 50px;
  margin-bottom: 15px;
  padding: 10px 5px;
}

.listContainer {
  padding-bottom: 150px;
}

.searchInput {
  border: 1px solid #0f4b73;
  border-radius: 50px;
}

.selector {
  /* width: 200px; */
  height: 38px;
  border: 1px solid #0f4b73;
  border-radius: 50px;
  background-color: white;
  /* color: white; */
}

.selector:active,
.selector:focus {
  outline: none;
}
