.contactUsHeader {
  /* height: 1000px; */
  background: transparent linear-gradient(180deg, 
  #074673 17%,
   #2677A0 51%,
    #368FB7 60%,
     #5EA6C6C1 75%,
     #83BBD488 85%,
     #AAD2E34C 100%
    ) 0% 0% no-repeat padding-box !important;
  }

.imgBtc{
  margin-top: 10%;
}

.imgdiv2{
  margin-top: 20%;
}

.titleAEM{
  color: #FFFFFF;
  font-size: 58px;
  font-weight: 900;
}

.container-ipad{
  background-image: url("../../assets/contactUs/btcopacity.png");
  /* opacity: 0.1; */
}

.subtileAem{
  margin-right: 15%;
  color: #FFFFFF;
  font-size: 18px;
}

.btnGroup_Submit {
  padding: 18px 30px;
  background-color: #254674;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 50px;
  font-size: 14px;

  color: #FFFFFF;
  width: 200px;
  height: 40px;

}

.btnGroup_Submit:hover {
  background-color: #888;
  color: #FFFFFF;
}
.mobile{
  display: none;
}
.ipad{
  display: none;
}
.desktop{
  /* display: block; */
}

@media only screen and (max-width: 600px) {
  .mobile{
    display: block;
  }
  .ipad{
    display: none;
  }
  .desktop{
    display: none;
  }
  .imgBtc{
    max-width: 100%;
    height: auto;
  }

  .titleAEM{
    margin-left: 15%;
    font-size: 25px;
  }
  .subtileAem{
    text-align: left;
    margin-top: 5%;
    margin-left: 15%;
    font-size: 25px;
  }
  .imgdiv2{
    margin-left: 15%;
  }
  .btnipad{
    margin-left: 10%;
    margin-top: 5%;
  }
  .btnGroup_Submit {
    /* padding: 15px 10px; */
    background-color: #254674;
    border-radius: 30px;
    font-weight: bold;
    /* margin-right: 50px; */
    font-size: 12px;
    width: 40%;
    color: #FFFFFF;
  }
}
@media only screen and (min-width: 600px) and (max-width: 860px) {
 
  .imgBtc{
    margin-top: 10%;
    margin-left: -90%;
  }
  .mobile{
    display: none;
  }
  .ipad{
    display: block;
  }
  .desktop{
    display: none;
  }

  .titleAEM{
    margin-left: 15%;
    font-size: 55px;
  }
  .subtileAem{
    text-align: left;
    margin-top: 5%;
    margin-left: 15%;
    font-size: 25px;
  }
  .imgdiv2{
    margin-left: 15%;
  }
  .btnipad{
    margin-left: 10%;
    margin-top: 5%;
  }
  .btnGroup_Submit {
    /* padding: 15px 10px; */
    background-color: #254674;
    border-radius: 30px;
    font-weight: bold;
    /* margin-right: 50px; */
    font-size: 18px;
    width: 30%;
    color: #FFFFFF;
  }
}