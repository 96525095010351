.colData {
    border         : 1px solid var(--Medium-grey, rgba(222, 222, 222, 0.80));
    min-width      : 200px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    text-align     : center;
}

.name {
    padding    : 10px 0px;
    color      : var(--Black, #202020);
    font-size  : 21px;
    font-style : normal;
    font-weight: 500;
    line-height: 29.6px;
    /* 140.952% */
}

.row-card_3 {
    width: 645px;
}

.row-card_4 {
    width: 855px;
}

.col-contain {
    margin-bottom: 30px;
}

.img-col {
    padding-top: 10px;
}


/* CSS */



@media screen and (max-width: 600px) {
    .img-col {
        width: 80%;
    }

    .colData {}
}