.pricing {
  padding-top   : 90px;
  text-align    : center;
  color         : #048DC1;
  font-size     : 18px;
  font-style    : normal;
  font-weight   : 700;
  line-height   : 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.header-journaler {
  color      : var(--Black, #202020);
  text-align : center;
  font-size  : 50px;
  font-style : normal;
  font-weight: 700;
  line-height: 62px;
  width      : 600px;
  /* 124% */
}

.text-journaler {
  background             : linear-gradient(99deg, rgba(3, 65, 161, 0.78) 46.44%, #22B7BB 84.79%);
  background-clip        : text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size              : 50px;
  font-style             : normal;
  font-weight            : 700;
  line-height            : 62px;
}

.sub-header-journaler {
  color     : var(--neutral-600, #6F6C90);
  text-align: center;

  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: 30px;
}

.form-select {
  padding      : 16px;
  border-radius: 4px;
  border       : 1px solid var(--Medium-grey, rgba(222, 222, 222, 0.80));
  background   : #FFF;
  color        : #979797;
  font-size    : 16px;
  font-style   : normal;
  font-weight  : 400;
}

.containerEnterprise {
  display        : flex;
  padding        : 44px;
  justify-content: space-between;
  align-items    : flex-end;
  border-radius  : 15px;
  background     : var(--Medium-Light-blue, #DFE7EB);
}

.enterprise {
  color      : var(--Navy, #0A4777);
  font-size  : 24px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.subEnterprise {
  color      : var(--neutral-800, #170F49);
  font-size  : 18px;
  font-style : normal;
  font-weight: 400;
  line-height: 29px;
}

.btcContactUs {
  display        : flex;
  width          : 255px;
  height         : 50px;
  justify-content: center;
  align-items    : center;
  gap            : 15px;
  flex-shrink    : 0;
  border-radius  : 4px;
  background     : var(--White, #FFF);

  color      : var(--Navy, #0A4777);
  font-size  : 14px;
  font-style : normal;
  font-weight: 500;
  line-height: 16px;
}

.support {
  background     : rgba(65, 161, 200, 0.10);
  display        : flex;
  height         : 262px;
  padding        : 40px 0px;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  gap            : 24px;
  flex-shrink    : 0;
}

.textCardContentLong {
  font-size     : 16px;
  color         : #000000;
  line-height   : 1.5;
  display       : inline-block;
  vertical-align: middle;
  padding-left  : 10px;
}

.save {
  color         : #000;
  font-size     : 14px;
  font-style    : normal;
  font-weight   : 400;
  line-height   : normal;
  padding-bottom: 10px;
}

.SubItemText {
  color         : #707070;
  line-height   : 1.5;
  display       : inline-block;
  vertical-align: middle;
  margin-bottom : 0px !important;

}

.contactUsLink {
  color: white;
}

.contactUsLink :hover {
  color          : white;
  text-decoration: none;
}

.priceDuration {
  font-weight: bold;
  color      : #FFFFFF;
  font-size  : 14px;
}

.priceDuration b {
  font-size: 28px;
  color    : #FFFFFF;
}

.price_duration {
  margin-top : 20%;
  font-weight: bold;
  color      : #FFFFFF;
  font-size  : 25px;
}

.textCardbody {
  /* padding-top: 10px; */
  font-size    : 16px;
  font-weight  : bold;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.titlePricing {
  font-size  : 18px;
  padding-top: 30p;
  color      : #FFFFFF
}

.textCardbodySub {
  font-size: 16px;
  color    : #ABAAAC;
}

.container-subscription {
  width      : 80%;
  margin-left: 10%;
}

.width-col {
  width: 15%;
}

.italictextCardbody {
  font-style: italic;
  color     : rgb(180, 172, 172);
}

.cardName {
  height            : 70px;
  background        : #DCF0F7;
  text-align        : center;
  color             : #000000;
  font-size         : 24px;
  font-weight       : bold;
  /* border-radius  : 15px 0px 0px 0px; */
  border-right-style: dotted;
  border-color      : white;
  position          : -webkit-sticky;
  position          : sticky;
  top               : 75px;
}

.cardBody {
  height            : 136px;
  flex-direction    : column;
  padding-top       : 20px;
  background        : #074673;
  color             : white;
  text-align        : left;
  border-radius     : 15px 0px 0px 0px;
  border-right-style: dotted;
  border-color      : white;
  border-width      : 2px;
  padding-left      : 20px;
  position          : sticky;
  top               : 140px
}

.cardBodyMid {
  height            : 136px;
  display           : flex;
  align-items       : center;
  flex-direction    : column;
  padding-top       : 20px;
  background        : #074673;
  color             : white;
  border-right-style: dotted;
  border-color      : white;
  border-width      : 2px;
  text-align        : left;
  position          : sticky;
  top               : 140px
}

.yearly-mobile {
  text-align: left;
  font-size : 12px;
  color     : rgb(180, 172, 172);
}

.monthly-mobile {
  font-size : 12px;
  text-align: left;
}

.monthly-mobile-text {
  font-size: 12px;
}

.yearly-mobile-text {
  font-size: 12px;
  color    : rgb(180, 172, 172);
}

.table-mobile {
  width       : 100%;
  margin-right: 10%;
  padding-left: 10%;
  padding-top : 10%;
}

.mobile-col {
  font-size  : 20px;
  font-weight: bold;
  min-width  : 150px
}

.mobile-col-head {
  font-size  : 20px;
  font-weight: bold;
  min-width  : 200px
}

.forfree {
  font-weight: bold;

}


.h1Bold {
  font-weight: bold;
  color      : rgb(1, 1, 1);
}

.text-table-body {
  font-size: 12px;
}

.italictextCardbody-mobile {
  font-style: italic;
  /* color  : rgb(180, 172, 172); */
  text-align: left;
  font-size : 10px;
  color     : red;
}

.greyBg {
  /* background-color: #F7F7F7; */
  padding   : 50px 0 70px 0;
  text-align: center;
}

.des {
  padding-bottom: 20px;
  margin-bottom : 0;
  color         : rgb(202, 74, 31);
  font-size     : 30px;
  font-weight   : bold;
}

.btn-switch {
  width      : 80%;
  padding-top: 10px;
  font-size  : 14px;
}






.planCard {
  border       : 0;
  box-shadow   : 0 3px 6px #00000029;
  border-radius: 10px;
}

.planPrice {
  margin   : 5px 0;
  font-size: 14px;
}

.planNum {
  color    : #0A4777;
  font-size: 20px;
}

.yearPrice {
  color    : #ABAAAC;
  font-size: 12px;
}

.planDetail {
  font-size: 13px;
}

.greyText {
  color       : #707070;
  padding-left: 10px;
}

.card-header {
  background-color: #074673;
  color           : white;
  font-size       : 18px;
  /* padding      : 5px 20px; */
  height          : 40px;
  font-weight     : bold;
}

.card-header_none {
  height          : 40px;
  background-color: white;
}

.card-body {
  padding: 20px 15px;
}

.card-header:first-child {
  border-radius: 10px 10px 0 0;
}

.card-footer {
  background-color: #0A4777;
  color           : white;
  /* height       : 25x; */
}

.card-footer:last-child {
  border-radius: 0 0 10px 10px;
}

/* ------- switch style ------- */
.swithcherDiv {
  display      : block;
  width        : max-content;
  padding      : 0;
  border-radius: 30px;
  background   : white;
  margin-bottom: 20px;
  box-shadow   : 0 3px 6px #00000029;
}

.monthBtn {
  width        : 152px;
  height       : 39px;
  border-radius: 30px;
  outline      : none;
  border       : none;
  background   : white;
  font-size    : 14px;
  color        : #707070;
}

.yearBtn {
  width        : 152px;
  height       : 39px;
  border-radius: 30px;
  outline      : none;
  border       : none;
  background   : white;
  font-size    : 14px;
  color        : #707070;
}

.monthBtn:active,
.monthBtn:focus,
.monthBtnChoosed:active,
.monthBtnChoosed:focus,
.yearBtn:active,
.yearBtn:focus,
.yearBtnChoosed:active,
.yearBtnChoosed:focus {
  background-color: #0a4777;
  outline         : none;
  border          : none;
  color           : white;
}

.monthBtnChoosed {
  width           : 152px;
  height          : 39px;
  border-radius   : 30px;
  outline         : none;
  border          : none;
  background-color: #0a4777;
  font-size       : 14px;
  color           : white;
}

.yearBtnChoosed {
  width           : 152px;
  height          : 39px;
  border-radius   : 30px;
  outline         : none;
  border          : none;
  background-color: #0a4777;
  font-size       : 14px;
  color           : white;
}

.blueNumber {
  color: #226E9D;
}

.flagIcon {
  width       : 50px;
  margin-right: 20px;
}

.flagButton {
  outline   : none;
  border    : none;
  background: transparent;
}

.flagButton:active,
.flagButton:focus {
  outline   : none;
  border    : none;
  background: transparent;
}

.comment {
  color      : #707070;
  font-size  : 12px;
  padding-top: 40px;
}

.cardContainer {
  text-decoration: none;
  border-radius  : 10px;
  margin-bottom  : 50px;
}

.cardContentColor {
  line-height        : 65px;
  text-align         : center;
  height             : 65px;
  background         : #F7F7F7;
  border-bottom-style: dotted;
  border-right-style : dotted;
  border-color       : #d6d6d6;
  border-width       : 2px;
  text-align         : center;
  align-items        : center;
  font-size          : 18px;
}

.nopadding {
  padding: 0 !important;
  margin : 0 !important;
}





.signupSectionContainerPricing {
  /* margin-top: -80px; */
  text-align         : center;
  padding            : 120px 10px;
  color              : white;
  background-image   : url(../../../assets/journaler/solutions/accountImage.png);
  /* background      : #094674 0% 0% no-repeat padding-box; */
  background-position: center;
  background-size    : cover;

}

/* .carousel-inner{
  margin-left: 5%;
} */


.cardName1 {
  height     : 70px;
  background : #ffffff;
  text-align : center;
  color      : #000000;
  font-size  : 24px;
  font-weight: bold;
  position   : -webkit-sticky;
  position   : sticky;
  top        : 70px;
}




.cardNameMid {
  height            : 70px;
  background        : #DCF0F7;
  text-align        : center;
  color             : #000000;
  font-size         : 24px;
  font-weight       : bold;
  border-right-style: dotted;
  border-color      : white;
  position          : sticky;
  position          : -webkit-sticky;
  top               : 60px;
}

.cardContentFirstColor {

  line-height        : 65px;
  text-align         : left;
  background         : #F7F7F7;
  border-right-style : dotted;
  border-bottom-style: dotted;
  border-left-style  : dotted;
  border-color       : #d6d6d6;
  border-width       : 2px;
  height             : 65px;
  font-weight        : bold;
}

.textCardContent {
  padding-left  : 10px;
  font-size     : 16px;
  color         : #000000;
  overflow      : hidden;
  text-overflow : ellipsis;
  line-height   : 1.5;
  display       : inline-block;
  vertical-align: middle;
  margin-bottom : 0px !important;

  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    font-size: 12px;

  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 10px;

  }
}

.cardContentFirstColor {
  padding-left       : 5px;
  line-height        : 65px;
  text-align         : left;
  background         : #F7F7F7;
  border-right-style : dotted;
  border-bottom-style: dotted;
  border-left-style  : dotted;
  border-color       : #d6d6d6;
  border-width       : 2px;
  height             : 65px;
}

.cardContentFirst {
  padding-left       : 15px;
  line-height        : 65px;
  text-align         : left;
  background         : #FFFFFF;
  border-right-style : dotted;
  border-bottom-style: dotted;
  border-left-style  : dotted;
  border-color       : #d6d6d6;
  border-width       : 2px;
  height             : 65px;
}



.cardHeader {
  background         : #074673;
  border-top-style   : dotted;
  border-right-style : dotted;
  border-bottom-style: dotted;
  border-left-style  : dotted;

  border-color: #d6d6d6;
  border-width: 2px
}


.cardBodyEnd {
  height        : 136px;
  display       : flex;
  align-items   : center;
  flex-direction: column;
  padding-top   : 20px;
  background    : #074673;
  color         : white;
  border-width  : 2px;
  text-align    : left;
  position      : sticky;
  top           : 100px
}

.ctus {
  font-size  : 20px;
  color      : #ffffff;
  padding-top: 15px;

  @media only screen and (min-width: 300px) and (max-width: 1366px) {
    font-size  : 20px;
    padding-top: 15px;
  }
}

.cardNameLast {
  height       : 70px;
  background   : #DCF0F7;
  text-align   : center;
  color        : #000000;
  font-size    : 24px;
  font-weight  : bold;
  border-radius: 0px 15px 0px 0px;
  position     : sticky;
  top          : 60px;
}

.cardContent {
  height             : 65px;
  line-height        : 65px;
  text-align         : center;
  background         : #FFFFFF;
  border-bottom-style: dotted;
  border-right-style : dotted;
  border-color       : #d6d6d6;
  border-width       : 2px;
  text-align         : center;
  align-items        : center;
  font-size          : 18px;
}



.cardFooter {
  display         : flex;
  align-items     : center;
  justify-content : center;
  height          : 45px;
  background-color: #048DC0;
  font-size       : 14px;
  font-weight     : bold;
  color           : #ffffff;
  border-radius   : 30px;
  margin-top      : 15px;
  margin-bottom   : auto;
  margin-left     : auto;
  margin-right    : auto;
  width           : 60%;

  @media only screen and (min-width: 900px) and (max-width: 1366px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 10px;
  }
}


.cardFooterSplit {
  height: 45px;
}




@media only screen and (max-width: 500px) {
  .pricingHeader {
    background-color: #2677A0;
  }

  .container-row {
    width: 600px;
  }

  .h1Bold {
    font-size: 20px;
  }

  .textName {
    font-size: 12px;
  }

  .titlePricing {
    font-size: 16px;
  }

  .container-subscription {
    width: 200%;
  }

  .pricingHeader {
    height: 500px;
  }

  .italictextCardbody {
    font-size: 12px;
  }

  .textCardContentLong {
    font-size    : 12px;
    width        : 100px;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .cardName {
    top: 5px
  }

  .cardBodyMid {
    top: 70px
  }

  .cardBody {
    top: 70px
  }

  .pricingHeader {
    padding-top: 20px;
  }

  .greyBg {
    padding: 20px 0 70px 0;
  }

  .des {
    margin-left: 5%;
    width      : 90%;
    font-size  : 16px;
  }

  .containerSave {
    width: 200px;
  }

  .swithcherDiv {
    width: 150px;
  }

  .header-journaler {
    width      : 80%;
    font-size  : 30px;
    line-height: 30px;
  }

  .text-journaler {
    font-size: 30px;

  }

  .sub-header-journaler {
    padding-top: 5px;
    font-size  : 14px
  }

  .yearBtn {
    box-shadow: 0 3px 6px #00000029;
  }

  .monthBtn {
    box-shadow: 0 3px 6px #00000029;

  }

  .btcContactUs {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 860px) {
  .screenshotImg {
    display: none;
  }

  .textName {
    font-size  : 18px;
    padding-top: 15%;
  }

  .textCardContentLong {
    font-size    : 12px;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .SubItemText {
    font-size: 11px;
  }

  .contactUsLink {
    font-size: 11px;
  }

  .priceDuration {
    font-size: 12px;
  }

  .priceDuration b {
    font-size: 20px;

  }

  .price_duration {
    font-size: 13px;
  }

  .textCardbody {
    font-size: 14px;
  }

  .textCardbodySub {
    font-size: 14px;
  }

  .h1Bold {
    font-size: 16px;
  }

  .titlePricing {
    font-size: 14px;
  }

}

@media only screen and (min-width: 860px) and (max-width: 1400px) {
  .h1Bold {
    font-size  : 53px;
    font-weight: bold;
  }

  .titlePricing {
    font-size  : 25px;
    font-weight: bold;
  }

  .textName {
    font-size  : 18px;
    padding-top: 15%;
  }

  .textCardContentLong {
    font-size    : 14px;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .SubItemText {
    font-size: 12px;
  }

  .contactUsLink {
    font-size: 12px;
  }

  .priceDuration {
    font-size: 12px;
  }

  .price_duration {
    font-size: 14px;
  }

  .priceDuration b {
    font-size: 20px;
  }

  .textCardbody {
    font-size: 16px
  }

  .textCardbodySub {
    font-size: 12px;
  }

  .italictextCardbody {
    font-size: 12px;
  }

}

@media only screen and (min-width: 1400px) and (max-width: 1800px) {
  .h1Bold {
    font-size  : 53px;
    font-weight: bold;
  }

  .titlePricing {
    font-size  : 25px;
    font-weight: bold;
  }

  .textName {
    font-size  : 18px;
    padding-top: 15%;
  }

  .textCardContentLong {
    font-size    : 14px;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .SubItemText {
    font-size: 14px;
  }

  .contactUsLink {
    font-size: 12px;
  }

  .priceDuration {
    font-size: 18px;

  }

  .price_duration {
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin-top     : 0%;
    font-size      : 16px;
  }


  .textCardbody {
    font-size: 16px
  }

  .textCardbodySub {
    font-size: 14px;
  }

  .italictextCardbody {
    font-size: 12px;
  }

  .cardBodyMid {
    /* padding-top: 15px; */
    display        : flex;
    justify-content: center;
    align-items    : center;
  }

  .priceDurationMonth {
    font-size: 14px;
  }
}